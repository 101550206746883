import React from 'react';
import classNames from 'classnames';
import stylesOverlayForm from '@components/projectForm/overlayForm.module.scss';
import stylesForms from '../formColumns/forms.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { TextInput } from 'components/form/textInput';
import { Fieldset } from '../fieldSet/fieldSet';
import { Color } from '../color/color';
import { Acronym } from '../acronym/acronym';
import { Button } from '../button/button';
import projectStyles from '@components/projectForm/projectForm.module.scss';
import dataService from '../../services/dataService';
import { RoundButton } from '../roundButton/roundButton';
import { MdAdd as AddIcon } from 'react-icons/md';
import { List } from '../list/list';
import { Avatar, avatarOptions } from '@components/avatar/avatar';
import { SelectInput } from '@components/form/selectInput';
import users from '@redux/modules/users';
import { apiUrls } from '@constants/apiurls';
import styles from './contactForm.module.scss';

export const ContactForm = (props) => {
  const {
    className,
    yupSchema,
    colors,
    seniorityLevels,
    isEditing,
    client,
    handleSubmit,
    contacts,
    skipHandler,
    state,
    setState,
    departments,
  } = props;

  const {
    data: usersData,
    isLoading: usersLoading,
    error: usersError,
    isSuccess: usersSuccess,
  } = users.list().useUserslistQuery({ params: '', extendedPath: apiUrls.getUsers() });
  useEffect(() => {
    if (usersData?.data) {
      let result = dataService.autoCompletionParser('users', usersData?.data);
      setState((prevState) => ({ ...prevState, users: result }));
    }
  }, [usersData]);

  const userDetails = (e, data) => {
    let selectedUser = state.users?.find((data) => data.id === e);
    setState((prevState) => ({ ...prevState, contactAvatar: data.avatar }));
    form.reset({
      id: data.id,
      first_name: data.name,
      last_name: data.surname,
      email: data.email,
      professional_title: data.pro_title,
      contact_number: data.contact_number,
      skype: data.skype,
      department_id: data.department,
      role_id: data.role_id || 7,
    });
  };

  const form = useForm({
    resolver: yupResolver(yupSchema),
    mode: 'all',
    // defaultValues: initialValues,
    shouldUnregister: true,
  });

  return (
    <>
      <FormProvider {...form}>
        <div>
          <form
            className={classNames(
              'form',
              stylesForms.form,
              projectStyles.projectFormContainer,
              className,
              stylesOverlayForm.overlayForm,
            )}
            onSubmit={form.handleSubmit(handleSubmit)}
          >
            <div>
              <div className="form-section">
                <div
                  className={classNames(stylesForms.formInnerWrapper, 'form-inner-wrapper px-2')}
                >
                  <>
                    <div className="mt-5 mb-4 d-flex justify-content-center">
                      <Avatar
                        {...avatarOptions(state.contactAvatar, 'contact')}
                        src={state.contactAvatar}
                        className={classNames(styles.avatar, styles.img_avatar)}
                        size={55}
                      />{' '}
                    </div>

                    <SelectInput
                      name="id"
                      options={state?.users}
                      formatOptionLabel={(user) => (
                        <div className={styles.usersDropdown}>
                          <Avatar
                            src={user.avatar}
                            className={classNames(styles.avatar, styles.img_avatar)}
                            size={25}
                          />
                          <span>{user.label}</span>
                        </div>
                      )}
                      mode="single"
                      required={true}
                      searchable={true}
                      onChange={(e, data) => userDetails(e, data)}
                    />

                    <TextInput label="Name" autoFocus name="first_name" />

                    <TextInput label="Surname" autoFocus name="last_name" />

                    <TextInput label="Email" autoFocus name="email" />

                    <TextInput
                      placeholder="e.g. General Manager"
                      label="Professional Title"
                      autoFocus
                      name="professional_title"
                    />

                    <SelectInput name="department_id" label="Department" options={departments} />

                    <TextInput label="Tel Number" autoFocus name="contact_number" />

                    <TextInput label="Skype" autoFocus name="skype" />

                    {/* <Button
                        important
                        type="submit"
                        // onClick={handleSubmit((values) =>
                        //   this.props.onFormSubmit({
                        //     ...values,
                        //     projectCreateSubmit: true,
                        //   }),
                        // )}
                      >
                        {isEditing ? 'Save changes' : 'Add project'}
                      </Button> */}
                    <div className="mt-4">
                      <Button important submit type="submit">
                        Add client contact
                      </Button>
                    </div>

                    <Button
                      cancel
                      onClick={() =>
                        setState((prevState) => ({ ...prevState, addContacts: false }))
                      }
                    >
                      Cancel
                    </Button>
                  </>
                </div>
              </div>
            </div>

            {/* <If condition={actions}>
            <div className={classNames(stylesForms.formActionsWrapper, 'form-actions-wrapper')}>
              {map(actions, (a, i) => ({ ...a, key: i }))}
            </div>
          </If>  */}
          </form>
        </div>
      </FormProvider>
    </>
  );
};
