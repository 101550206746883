import { baseSliceWithTags } from '../baseSlice';

const authApi = baseSliceWithTags.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (auth) => ({
        url: '/rest/users/login',
        method: 'POST',
        data: auth,
      }),
    }),
  }),
});
export const { useLoginMutation } = authApi;
export default authApi;
