import React from 'react';
import { NavLink } from 'react-router-dom';
import { size } from 'lodash';

import { BaseCard } from '../baseCard/baseCard';
import Role from '../role/role';
import { MdOutlineWc as CandidatesIcon } from 'react-icons/md';
import { avatarOptions } from '../avatar/avatar';

import styles from './contactCard.module.scss';
import { routesPath } from '../../constants/routesPath';

export const ContactCard = ({ client, contact, onRemove, onEdit, color, onClick }) => (
  <>
    {contact ? (
      <BaseCard
        item={contact}
        color={color}
        avatar={{ ...avatarOptions(contact?.user ?? contact, 'contact'), size: 40 }}
        title={contact.first_name + ' ' + contact.last_name}
        subhead={contact?.title}
        // link={routes.projectPipeline(project.client.id, project.id)}
        onEdit={onEdit}
        size={'medium'}
        onRemove={onRemove}
        onClick={onClick}
      ></BaseCard>
    ) : (
      ''
    )}
  </>
);

// ProjectCard.propTypes = {
//   project: React.PropTypes.object.isRequired,
// };
