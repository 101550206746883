import React, { useState, useEffect } from 'react';
import { PipelineNav } from '@components/pipelineNav/pipelineNav';
import styles from './settingsContainer.module.scss';
import { Header } from 'components/header/header';
import classNames from 'classnames';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export const SettingsContainer = (props) => {
  const [contentActive, setContentActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname == '/settings/pipelines') {
      setContentActive(true);
    }else if(location.pathname == '/settings/roles') {
      setContentActive(true);
    }
  }, [location]);
  const updateContent = () => {
    navigate('/settings');
    setContentActive(!contentActive);
  };
  return (
    <>
      <div className={classNames(styles.settingsMain)}>
        <div className="d-none d-md-block">
          <Header titleLeft={'Account Settings'} />
        </div>
        {location.pathname == '/settings/pipelines' && contentActive ? (
          <div className="d-block d-md-none">
            <Header backLinkMobile={() => updateContent()} newEntry>
              Pipeline Templates
            </Header>
          </div>
        ) : (
          ''
        )}
        {location.pathname == '/settings/roles' && contentActive ? (
          <div className="d-block d-md-none">
            <Header backLinkMobile={() => updateContent()} newEntry>
              Roles And Permissions
            </Header>
          </div>
        ) : (
          ''
        )}
        {location.pathname == '/settings' ? (
          <div className="d-block d-md-none">
            <Header titleLeft={'Account Settings'} />
          </div>
        ) : (
          ''
        )}
        <div>
          <div className={styles.jobsWrapper}>
            <div className={styles.matchCompCardContent}>
              {/* TITLE */}

              <div className={classNames(styles.contentArea)}>
                <div
                  className={classNames(styles.tabs, {
                    ['d-block d-sm-none']: !contentActive,
                    ['d-none d-sm-block']: contentActive,
                  })}
                >
                  <PipelineNav />
                </div>
                <div
                  className={classNames(styles.tabsContent, {
                    ['d-block']: contentActive,
                    ['d-none d-sm-block']: !contentActive,
                  })}
                >
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
