import axios from 'axios';
import errorParser from '../services/errorParser';
import toast from 'react-hot-toast';

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, csrf, params }) => {
    const API = axios.create({
      baseURL: baseUrl,
    });

    API.interceptors.response.use(
      (res) => {
        return res;
      },
      (error) => {
        const expectedError = error.response?.status >= 400 && error.response?.status < 500;
        if (!expectedError) {
          toast.error('An unexpected error occurrred.');
        }

        if (error.response?.status === 401) {
          //   Storage.clearJWTToken();
          window.location = '/login';
        }

        return Promise.reject(error);
      },
    );

    try {
      let headers = {};
      if (csrf) headers = { ...csrf };

      const result = await API({
        url: url,
        method,
        data,
        headers,
        params: params ? params : '',
        baseURL: baseUrl,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: { status: errorParser.parseError(err.response?.status), data: err.response?.data },
      };
    }
  };
