import React from 'react';
import ReactAvatar from 'react-avatar';
import styles from './avatar.module.scss';

import {
  map,
  reduce,
  omitBy,
  sortBy,
  split,
  join,
  reverse,
  take,
  head,
  size,
  indexOf,
  upperCase,
  floor,
  isEmpty,
} from 'lodash';
// import { fullName } from 'redux/modules/talent';

export const MAX_INITIALS = 2;

export const COLORS = [
  'F7412C',
  'EC1562',
  '9D1DB2',
  '3F4DB8',
  '1294F6',
  '00BCD7',
  '48B14C',
  'CDDE20',
  'FEC200',
];

export function avatarOptions(entity, type) {
  switch (type) {
    case 'client':
      return {
        src: entity.logo && entity.logo,
        name: entity.name,
      };
    case 'talent':
      return {
        name: entity?.full_name,
        src: entity?.avatar,
      };

    case 'contact':
      return {
        src: entity?.avatar && entity?.avatar,
        // name: fullName(entity),
        name: entity?.label,
        email: entity?.email,
      };
    case 'user':
      return {
        src: entity?.avatar && entity?.avatar,
        // name: fullName(entity),
        name: entity?.label,
        email: entity?.email,
      };
    case 'project':
      return omitBy(
        {
          name: entity?.title,
          color: entity?.color,
        },
        isEmpty,
      );
    default:
      return {
        name: entity?.name,
        email: entity?.email,
      };
  }
}

function initialsFromName(name) {
  const candidates = split(name, ' ');
  const selected = take(reverse(sortBy(candidates, size)), MAX_INITIALS);
  const reordered = sortBy(selected, (w) => indexOf(candidates, w));
  const initials = map(reordered, head);
  return map(initials, upperCase);
}

function colorFromInitials(initials) {
  const max = MAX_INITIALS * 'Z'.charCodeAt();
  const score = reduce(initials, (sum, item) => sum + item.charCodeAt() || 0, 0);
  const percentage = max > 0 ? (score * 100) / max : 0;
  const index = floor((percentage * size(COLORS)) / 100);
  return COLORS[index];
}

export const Avatar = (props) => {
  const { name, color, initials, src, className, ...rest } = props;

  // let initialsText = null;
  // if (isEmpty(props.initials)) {
  //   initialsText = initialsFromName(name);
  // } else {
  //   initialsText = initials;
  // }
  return (
    <ReactAvatar
      // name={join(initialsText, ' ')}
      name={name}
      src={src}
      color={'#' + (color || colorFromInitials(initials))}
      round
      className={styles[className]}
      {...rest}
    />
  );
};
