import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { RoundButton } from '../../components/roundButton/roundButton';
import { JobIndexList } from '../../components/jobIndexList/JobIndexList';
import { Heading } from '../../components/heading/heading';
import jobs from '../../redux/modules/jobs';
import { apiUrls } from '../../constants/apiurls';
import styles from '@components/jobIndexCard/JobForm.module.scss';
import { listApiCall } from '../../services/listingService';
import { MdAdd as AddIcon } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { routesPath } from '../../constants/routesPath';
import { Spinner } from '../../components/loader/loader';
import toast from 'react-hot-toast';
import { Page } from 'components/page/page';
import { isEmpty } from 'lodash';
import { EmptyCard } from '../../components/emptyCard/emptyCard';
import { Button } from '../../components/button/button';

export const JobsContainer = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    colors: [],
  });

  const [deleteJob, deleteJobData] = jobs.delete().useJobdeleteMutation();

  const {
    data: jobsData,
    isFetching: isJobsLoading,
    error: jobsError,
    isSuccess: jobsSuccess,
  } = jobs
    .list()
    .useJoblistQuery(
      { params: { page: '1', limit: '100' }, extendedPath: apiUrls.getJobsListing() },
      { refetchOnMountOrArgChange: true },
    );

  useEffect(() => {
    getColors();
  }, []);

  useEffect(() => {
    if (jobsError) {
      toast.error('Failed To Get Jobs!');
    }
  }, [jobsError]);

  useEffect(() => {
    if (deleteJobData.isSuccess) {
      toast.success('Job Successfully Deleted!');
    }
    if (deleteJobData.isError) {
      toast.error('Sorry, something went wrong while fetching jobs.!');
    }
  }, [deleteJobData]);

  const getColors = async () => {
    try {
      const response = await listApiCall(apiUrls.getColors());

      setState((prevState) => ({ ...prevState, colors: response }));
    } catch (err) {}
  };

  return (
    <div className={classNames()}>
      {isJobsLoading ? (
        <div className="text-center">
          <Spinner height="100vh" width="100" />
        </div>
      ) : (
        <>
          <Page isNotSubmenu>
            <div className={classNames(styles.jobsWrapper)}>
              <div
                className={classNames(
                  styles.matchCompCardContent,
                  'd-flex',
                  'justify-content-between',
                  'align-items-center',
                  'mb-3',
                )}
              >
                {/* TITLE */}
                <div className={styles.sectionLabel}>
                  <Heading size="large">Job Posts</Heading>
                </div>

                <div className={styles.actions}>
                  <div className={styles.sectionAction}>
                    <RoundButton
                      size="large"
                      theme="blue"
                      onClick={() => navigate(routesPath.jobsFormPage, { replace: false })}
                    >
                      <AddIcon size={25} />
                    </RoundButton>
                  </div>
                </div>
              </div>
            </div>

            {!jobsData?.data?.jobs?.length ? (
              <EmptyCard
                isCard
                title="Let’s get started.\nAdd your first Job."
                description="Whose the company that you’re recruiting for? We first need to create job and then we can start by adding a projects we need to recruit for."
              >
                <Button
                  submit
                  onClick={() => navigate(routesPath.jobsFormPage, { replace: false })}
                  inline
                >
                  New Job
                </Button>
              </EmptyCard>
            ) : (
              <JobIndexList
                jobs={jobsData?.data?.jobs?.filter((job) => job.hidden === 1)}
                colors={state.colors}
                deleteJob={(id) =>
                  deleteJob({
                    body: { id: id },
                  })
                }
              />
            )}
          </Page>
        </>
      )}
    </div>
  );
};
