import React from 'react';
import { NavLink } from 'react-router-dom';
import { size } from 'lodash';

import { BaseCard } from '../baseCard/baseCard';
import Role from '../role/role';
import { MdOutlineWc as CandidatesIcon } from 'react-icons/md';
import { avatarOptions } from '../avatar/avatar';

import styles from '@components/recruiterCard/recruiterCard.module.scss';
import style from './teamCard.module.scss';

import { routesPath } from '../../constants/routesPath';
import classNames from 'classnames';

export const TeamCard = ({ client, team, onRemove, onEdit, color, onClick }) => (
  <>
    {team ? (
      <BaseCard
        item={team}
        color={color}
        avatar={{ ...avatarOptions(team, 'team'), size: 40 }}
        title={team?.title}
        projectCount={team?.projects?.length}
        // link={routes.projectPipeline(project.client.id, project.id)}
        onEdit={onEdit}
        size={'medium'}
        onRemove={onRemove}
        onClick={onClick}
        isTeamCard
      >
        <div className={style.teamCountWrapper}>
          <div className={style.teamCount}>{team?.recruiters?.length}</div>
          <div className={style.teamWrapper}>
            {team?.users?.slice(0, 5).map((data, i) => {
              return (
                <div className="">
                  <img src={data?.avatar} alt="" className={classNames(style.teamMember)} />
                </div>
              );
            })}
          </div>
          {/* <NavLink
            className={styles.candidates}
            to={routesPath.dashboard}
            // to={routes.projectPipeline(project.client.id, project.id)}
          >
            <CandidatesIcon size={20} /> {size(project.talent)} Candidates
          </NavLink> */}
          {/* <Role className={project.minimum_level}>{project.minimum_level}</Role> */}
        </div>
      </BaseCard>
    ) : (
      ''
    )}
  </>
);

// ProjectCard.propTypes = {
//   project: React.PropTypes.object.isRequired,
// };
