import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { isEmpty, map } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import ClientCard from '@components/clientCard/clientCard';
import { Spinner } from '@components/loader/loader';
import { Section } from '@components/section/section';
import { Wizard } from '@components/wizard/wizard';
import { apiUrls } from '@constants/apiurls';
import clients from '@redux/modules/clients';
import { fetchAutoCompleteData } from '@services/autoCompleteService';
import yupSchemaService from '@services/yupSchemaService';
import { List } from '@components/list/list';
import { BaseCard } from '@components/baseCard/baseCard';
import { MdPersonAdd as AddPersonIcon } from 'react-icons/md';
import { avatarOptions } from '@components/avatar/avatar';
import { Button } from '@components/button/button';
import { routesPath } from 'constants/routesPath';
import { useDispatch } from 'react-redux';
import { updateWizardStep } from '@redux/common/commonSlice';
import { RoundButton } from 'components/roundButton/roundButton';
import { MdAdd as AddIcon } from 'react-icons/md';
import { ContactForm } from '@components/contactForm/contactForm';

export const ContactFormContainer = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    colors: [],
    positionLevels: [],
    loading: false,
    contacts: false,
    users: null,
    contactAvatar: 'asad',
    departments: [],
    addContacts: false,
  });

  const getInitialData = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const autoCompletedFields = [{ type: 'departments', url: apiUrls.getDepartments() }];
      const promises = autoCompletedFields.map((field) =>
        fetchAutoCompleteData(field.type, field.url),
      );

      const response = await Promise.all(promises);
      setState((prevState) => ({
        ...prevState,
        departments: response[0],
        loading: false,
      }));
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const {
    data: clientData,
    error: clientError,
    isFetching: clientLoading,
    refetch: clientsRefetch,
  } = clients.list().useClientsdetailQuery(
    { params: { id: params.clientId }, extendedPath: apiUrls.getClientDetail() },
    {
      skip: !params.clientId,
    },
  );

  const [clientUpdate, clientUpdateData] = clients.form().useClientsupdateMutation();

  useEffect(() => {
    dispatch(
      updateWizardStep({
        step: 1,
      }),
    );
    getInitailData();
  }, []);

  const getInitailData = async () => {
    const initailDataList = [
      { url: apiUrls.getColors() },
      { type: 'language', url: apiUrls.getPositionLevels() },
    ];
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const promises = initailDataList.map((data) => fetchAutoCompleteData(data.type, data.url));
      const response = await Promise.all(promises);

      setState((prevState) => ({
        ...prevState,
        colors: response[0],
        positionLevels: response[1],
        loading: false,
      }));
    } catch (err) {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleSubmit = (values) => {
    if (values.id) {
      let contactDetail = {};
      contactDetail['id'] = params?.clientId;
      const previousContacts = clientData.data.client_contacts.map((data) => ({
        id: data.id,
      }));
      contactDetail['client_contacts'] = [...previousContacts, { id: values.id }];
      clientUpdate(contactDetail);
    } else {
      let contactDetail = {};
      contactDetail['id'] = params?.clientId;
      const previousContacts = clientData.data.client_contacts.map((data) => ({
        id: data.id,
      }));
      values.password = 'badBackend';
      contactDetail['client_contacts'] = [...previousContacts, values];
      clientUpdate(contactDetail);
    }
    setState((prevState) => ({
      ...prevState,
      addContacts: false,
    }));
  };

  const deleteClientContact = (id) => {
    let contactDetail = {};
    contactDetail['id'] = params?.clientId;
    const previousContacts = clientData.data.client_contacts.map((data) => ({
      id: data.id,
    }));
    const updatedContacts = previousContacts.filter(function (contact) {
      return contact.id != id;
    });
    contactDetail['client_contacts'] = updatedContacts;
    clientUpdate(contactDetail);
  };

  const findColor = (colorId) => {
    let color = state.colors?.find((col) => col.id === colorId);
    return color?.hex;
  };

  return (
    <>
      <Wizard
        step={3}
        title="Now that the client is setup. Let’s add some client contacts."
        icon={<AddPersonIcon size={25} />}
        action={
          state?.addProjects ? (
            <>
              <Button
                inline
                regular
                //  onClick={() => finalize() && push(routes.dashboard)}
                onClick={() => navigate(routesPath.dashboard, { replace: false })}
              >
                I’ll add projects later
              </Button>
              <p style={{ color: '#686868' }}>
                You can add projects at any stage\n from the projects menu
              </p>
            </>
          ) : (
            <>
              <Button
                inline
                submit
                onClick={() =>
                  navigate(routesPath.addProjects(params?.clientId), {
                    replace: false,
                  })
                }
              >
                Done
              </Button>
              <p className="mt-3" style={{ color: '#686868' }}>
                You can add projects at any stage from the projects menu
              </p>
            </>
          )
        }
      >
        <div>
          <ClientCard isEditable={false} client={clientData?.data}>
            <Section title="Add Contacts" headingSize="large" contentVisible={true}>
              {clientLoading && clientUpdate.isLoading ? (
                <Spinner height={100} width={100} />
              ) : (
                <div className="form-section">
                  {state?.addContacts && (
                    <ContactForm
                      yupSchema={yupSchemaService.addContact()}
                      colors={state.colors}
                      seniorityLevels={state.positionLevels}
                      client={clientData?.data}
                      clientUpdate={clientUpdate}
                      clientUpdateData={clientUpdateData?.data}
                      loading={clientUpdateData?.isLoading || clientLoading}
                      handleSubmit={handleSubmit}
                      state={state}
                      setState={setState}
                      departments={state.departments}
                    />
                  )}
                  {!isEmpty(clientData?.data?.client_contacts) &&
                    !clientUpdate.isLoading &&
                    !state?.addContacts && (
                      <List>
                        {map(clientData?.data?.client_contacts, (contact) => (
                          <>
                            <BaseCard
                              onRemove={() => deleteClientContact(contact?.id)}
                              key={`contact-${contact?.id}`}
                              item={contact}
                              title={
                                contact?.user?.full_name ??
                                contact?.first_name + ' ' + contact?.last_name
                              }
                              size="medium"
                              avatar={{
                                ...avatarOptions(contact?.user ?? contact, 'contact'),
                                size: 40,
                              }}
                              subhead={contact?.professional_title}
                            />
                          </>
                        ))}
                      </List>
                    )}
                  {!state.addContacts && (
                    <div className="d-flex justify-content-center py-3">
                      <RoundButton
                        onClick={() =>
                          setState((prevState) => ({ ...prevState, addContacts: true }))
                        }
                      >
                        <AddIcon size={30} />
                      </RoundButton>
                    </div>
                  )}
                </div>
              )}
            </Section>
          </ClientCard>
        </div>
      </Wizard>
    </>
  );
};
