import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from '../clientForm/clientForm.module.scss';
import quilStyles from './quil.module.scss';

import { useFormContext, useWatch } from 'react-hook-form';

export const Quil = (props) => {
  const { name, label, type, required, placeHolder, min, fieldDescription } = props;
  const {
    register,
    formState: { errors },
    unregister,
    setValue,
    trigger,
  } = useFormContext();
  const originalValue = useWatch({ name: name }) || '';

  useEffect(() => {
    register(name);
  }, [register, name]);

  const handleQuil = (data) => {
    setValue(name, data, { shouldValidate: true });
  };

  return (
    <div className="form-group my-4" style={{ clear: 'both' }}>
      <label className={styles.clientDescriptionLabel} htmlFor={name}>
        {required && <small className="text-danger">* </small>}
        {label}
        {fieldDescription && (
          <span className={quilStyles.description + ' ' + 'd-block' + ' ' + 'mb-3'}>
            {fieldDescription}
          </span>
        )}
      </label>
      <div>
        <ReactQuill
          className={quilStyles.QuillEditor}
          onChange={handleQuil}
          value={originalValue}
          modules={{
            toolbar: [
              [
                'bold',
                'italic',
                'underline',
                'blockquote',
                { list: 'ordered' },
                { list: 'bullet' },
              ],
            ],
          }}
        />
        <small className="text-danger float-start" style={{ clear: 'both' }}>
          {errors[name]?.message}
        </small>
      </div>
    </div>
  );
};
