import React, { useEffect, useState } from 'react';
import { isEmpty, map, size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@components/button/button';
import { EmptyCard } from '@components/emptyCard/emptyCard';
import PageListSection from '@components/pageListSection/pageListSection';
import styles from '@components/dashboard/dashboard.module.scss';
import { ContactCard } from '@components/contactCard/contactCard';
import { handleCurrentView, handleViewsData } from '../../redux/common/commonSlice';
import { views } from 'constants/views';
import classnames from 'classnames';
import { RecruiterCard } from 'components/recruiterCard/recruiterCard';
import { useNavigate } from 'react-router-dom';
import { routesPath } from '../../constants/routesPath';
import teams from '../../redux/modules/teams';
import dataService from '../../services/dataService';
import { Spinner } from '../loader/loader';

export const RecruitersListing = (props) => {
  const {
    contacts,
    clients,
    visibleContacts,
    visibleClients,
    contactsFilter,
    clientsFilter,
    onProjectSearch,
    onProjectAdd,
    onProjectRemove,
    onProjectEdit,
    onClientSearch,
    onClientAdd,
    onClientRemove,
    onClientEdit,
    searchedContactsData,
  } = props;

  const navigate = useNavigate();

  const {
    data: teamsData,
    error: teamsError,
    isFetching: teamsLoading,
  } = teams.list().useTeamslistQuery();

  console.log('teamData', teamsData);

  const [parsedRecruiters, setParsedRecruiters] = useState(null);

  useEffect(() => {
    if (teamsData) {
      setParsedRecruiters(dataService.parseRecruitersListing(teamsData?.data));
    }
    console.log("Data",dataService.parseRecruitersListing(teamsData?.data))
  }, [teamsData]);

  const dispatch = useDispatch();

  return (
    <div className={classnames(styles.dashboardWrappper, 'mainPanelRight')}>
      <PageListSection
        // title="Client Contacts"
        onSearch={1}
        searchQuery={contactsFilter}
        onAdd={() => navigate(routesPath.recruitersAdd(), { replace: false })}
        searchPlaceholder="Search Recruiter"
      >
        {teamsLoading ? (
          <Spinner height={100} width={100} />
        ) : isEmpty(parsedRecruiters) ? (
          <EmptyCard
            isCard
            title="Things are looking a little bare here."
            description="You haven’t added any recruiter yet."
          >
            <Button submit onClick={onProjectAdd} inline>
              New Recruiter
            </Button>
          </EmptyCard>
        ) : (
          <div
            className={classnames(styles.pageSectionContainer, 'flex-wrap')}
            style={{ width: `${size(contacts) * (364 + 20) - 20}px` }}
          >
            {map(parsedRecruiters, (recruiter) => (
              <RecruiterCard
                key={`recruiter-${recruiter.id}`}
                recruiter={recruiter}
                onRemove={onProjectRemove}
                onEdit={onProjectEdit}
                client={clients}
                onClick={() =>
                  dispatch(
                    handleViewsData({ contact: contacts, currentView: views.contactProfile }),
                  )
                }
              />
            ))}
          </div>
        )}
      </PageListSection>
    </div>
  );
};
