import CreateTeam from 'components/createTeam/CreateTeam';
import React from 'react';

export const CreateTeamContainer = () => {
  return (
    <div>
      <CreateTeam />
    </div>
  );
};
