import React from 'react';
import { isEmpty, map, size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/button/button';
import { EmptyCard } from 'components/emptyCard/emptyCard';
import PageListSection from 'components/pageListSection/pageListSection';
import styles from 'components/dashboard/dashboard.module.scss';
import { ContactCard } from '@components/contactCard/contactCard';
import { handleCurrentView, handleViewsData } from '../../redux/common/commonSlice';
import { views } from 'constants/views';
import classnames from 'classnames';

export const ContactsListing = (props) => {
  const {
    contacts,
    clients,
    visibleContacts,
    visibleClients,
    contactsFilter,
    clientsFilter,
    onProjectSearch,
    onProjectAdd,
    onProjectRemove,
    onProjectEdit,
    onClientSearch,
    onClientAdd,
    onClientRemove,
    onClientEdit,
    searchedContactsData,
  } = props;

  const dispatch = useDispatch();
  return (
    <div className={classnames(styles.dashboardWrappper, 'mainPanelRight')}>
      <PageListSection
        title="Client Contacts"
        onSearch={onProjectSearch}
        searchQuery={contactsFilter}
        onAdd={onProjectAdd}
        searchPlaceholder="Search contact"
      >
        {isEmpty(contacts) ? (
          <EmptyCard
            isCard
            title="Things are looking a little\nbare here, recruiter."
            description="You haven’t created any contacts yet. Get started by looking at the example
          contact alongside or create your first contact now."
          >
            <Button submit onClick={onProjectAdd} inline>
              New contact
            </Button>
          </EmptyCard>
        ) : (
          <div
            className={classnames(styles.pageSectionContainer, 'flex-wrap')}
            style={{ width: `${size(contacts) * (364 + 20) - 20}px` }}
          >
            {map(contacts, (contact) => (
              <ContactCard
                key={`contact-${contact.id}`}
                contact={contact}
                onRemove={onProjectRemove}
                onEdit={onProjectEdit}
                client={clients}
                onClick={() =>
                  dispatch(handleViewsData({ contact: contact, currentView: views.contactProfile }))
                }
              />
            ))}
          </div>
        )}
      </PageListSection>
    </div>
  );
};
