import { BsFillFileEarmarkTextFill as FileTextIcon } from 'react-icons/bs';
import { AiFillFlag as FlagIcon } from 'react-icons/ai';
import { MdAdd as AddIcon } from 'react-icons/md';
import { EmptyCard } from 'components/emptyCard/emptyCard';

import { FaEllipsisH as EllipsisIcon } from 'react-icons/fa';

import classNames from 'classnames';
import { size } from 'lodash';
import ReactTooltip from 'rc-tooltip';

import { Tooltip } from 'components/tooltip/tooltip';
import { ProjectsDropdown } from 'components/projectsDropdown/projectsDropdown';
import { Avatar, avatarOptions } from 'components/avatar/avatar';
import { Heading } from 'components/heading/heading';
import { Button } from 'components/button/button';

import styles from './applicantCard.module.scss';
import { OverlayContainer } from '@containers/overlayContainer/OverlayContainer';
import { views } from '@constants/views';
import { useSelector } from 'react-redux';
import { useState } from 'react';

export const ApplicantCard = (props) => {
  const {
    applicant,
    openOverlay,
    coverLetter,
    projects,
    job,
    clientProjectsOptions,
    create,
    openTalentSidebar,
    fullName,
    currentJob,
    locationInfo,
    openapplicantSidebar,
    emptyMessage,
    coverLetterOpen,
    handleCoverLetterOpening,
    key,
  } = props;

  const avatarSize = 20;
  const currentUIState = useSelector((state) => state.commonReducer.viewsData);

  const [isCoverLetter, setIsCoverLetter] = useState(false);

  return (
    <div
      className={classNames(styles.col_1_3, ' col-6', styles.maxWidth240, {
        ['col-lg-5']: currentUIState?.currentView,
        ['col-lg-3']: !currentUIState?.currentView,
      })}
      key={key}
    >
      <div
        className={classNames(
          styles.compCardContainer,
          styles.smallMarginsGrid,
          styles.card,
          styles.applicantCard,
        )}
      >
        {emptyMessage && (
          <EmptyCard
            title="There were no results for your search"
            description="Tweak your search criteria for better results."
          />
        )}
        {!emptyMessage && (
          <>
            <div className={classNames(styles.col_1_1, styles.flagLinkContainer)}>
              {/*<If condition={ size(talent.cards) >= 1 } >*/}
              {applicant.avatar && (
                <ReactTooltip
                  placement="top"
                  overlay={
                    <Tooltip
                      title="Flagged candidate"
                      content="Candidate has either been added to a pipeline
                                      before, or a note or attachment has been left on candidate."
                    />
                  }
                >
                  <span className={classNames(styles.alert, styles.small, styles.green)}>
                    <FlagIcon size={14} />
                  </span>
                </ReactTooltip>
              )}
              {coverLetterOpen && isCoverLetter && (
                <OverlayContainer
                  closeOverlay={() => {
                    handleCoverLetterOpening();
                    setIsCoverLetter(false);
                  }}
                  currentView={coverLetterOpen && views.coverLetter}
                  talentCoverLetter={coverLetter}
                  talentName={applicant?.full_name}
                  talentJobTitle={applicant.secondHeading}
                  talentAvatar={applicant.avatar}
                />
              )}
              {coverLetter && (
                <a
                  className={classNames(
                    styles.alert,
                    styles.small,
                    styles.blue,
                    styles.cursor_pointer,
                  )}
                >
                  <FileTextIcon
                    size={13}
                    onClick={() => {
                      handleCoverLetterOpening();
                      setIsCoverLetter(true);
                    }}
                  />
                </a>
              )}
            </div>

            <div className={classNames(styles.compCardContent)}>
              <center>
                <div className={classNames(styles.avatarLink)} onClick={openTalentSidebar}>
                  <Avatar
                    avatar={{ ...avatarOptions(applicant, 'talent') }}
                    name={applicant?.full_name}
                    src={applicant.avatar}
                    className={classNames(styles.avatar, styles.img_avatar)}
                    size={avatarSize.large}
                  />
                  <Heading size="medium" className={classNames(styles.firstHeading, 'mt-3')}>
                    {applicant?.full_name}
                  </Heading>
                </div>
                <p className={styles.secondHeading} style={{ padding: '0px' }}>
                  {job?.title +
                    ', ' +
                    applicant?.professional_title +
                    ', ' +
                    applicant?.company?.name +
                    ', ' +
                    applicant?.country?.title}
                </p>
                <div className="d-flex justify-content-center">
                  <ProjectsDropdown
                    projectsData={projects}
                    applicant={applicant}
                    // onClick={(project, column) => {
                    //   create('cards', {
                    //     talent: talent.url,
                    //     pipeline: project.pipeline.url,
                    //     column,
                    //     before: null,
                    //   });
                    // }}
                    zIndex={12}
                    inline
                  >
                    <Button inline square submit size={'small'}>
                      <AddIcon size={15} style={{ verticalAlign: 'center' }} />
                      {/* </center> */}
                    </Button>
                  </ProjectsDropdown>
                  <Button
                    // onClick={openTalentSidebar}
                    inline
                    square
                    white
                    size={'small'}
                  >
                    <EllipsisIcon size={15} />
                  </Button>
                </div>
              </center>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
