import React from 'react';
import { RoundButton } from '../roundButton/roundButton';

import { BiSearch as SearchIcon } from 'react-icons/bi';

import styles from './searchButton.module.scss';

export const SearchButton = () => (
  <RoundButton className={styles.button} size={'medium'}>
    <SearchIcon size={10} />
  </RoundButton>
);
