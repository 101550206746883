import React from 'react';
import classNames from 'classnames';
import { MenuItem as ReactMenuItem } from 'react-menu-list';

import { CardTitle } from '../cardTitle/cardTitle';
// import { avatarSize } from 'redux/modules/ui';

import styles from '../nav/nav.module.scss';

export const MenuItemCard = ({ link, onClick, name, avatar, disabled, subhead }) => {
  return (
    // <ReactMenuItem
    //   className={`menu-item
    //   ${styles.menuItem}
    //   ${styles.menuItemCard}
    //   ${disabled ? 'menu-item-disabled' : ''}`}
    // >
    <CardTitle
      title={name}
      avatar={{ ...avatar, size: 30 }}
      link={link}
      onClick={onClick}
      size="small"
      subhead={subhead}
    />
    // </ReactMenuItem>
  );
};
