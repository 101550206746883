import React from 'react';
import classNames from 'classnames';

import styles from './page.module.scss';

export const Page = ({ children, isNotSubmenu }) => (
  <div className={classNames(styles.pageWrapper, { [styles.notSubMenuWrapper]: isNotSubmenu })}>
    {children}
  </div>
);
