import { authHeader } from '../../constants/authenticationHeader';
import { baseSliceWithTags } from '../baseSlice';

const form = (path, module) => {
  const formApi = baseSliceWithTags.injectEndpoints({
    endpoints: (builder) => {
      return {
        [module + 'create']: builder.mutation({
          query: (payload) => ({
            url: path + '/create',
            method: 'post',
            data: payload?.body,
            csrf: authHeader(),
            params: payload?.params,
          }),
          invalidatesTags: [module],
        }),
        [module + 'update']: builder.mutation({
          query: (body) => ({
            url: path + '/update',
            method: 'post',
            data: body,
            csrf: authHeader(),
          }),
          invalidatesTags: [module],
        }),
      };
    },
  });
  return formApi;
};
export default form;
