import React from 'react';
import classNames from 'classnames';
import { MdTune as FiltersIcon } from 'react-icons/md';

import styles from '../searchForm/searchForm.module.scss';
import { RoundButton } from '../roundButton/roundButton';
import { Heading } from '../heading/heading';
import styless from './searchFiltersSwitch.module.scss';

export const SearchFiltersSwitch = ({ onClick, isOpen, ...rest }) => (
  <div className={classNames(styles.filtersTriggerHorizontal, { [styles.isOpen]: isOpen })}>
    <RoundButton
      onClick={onClick}
      size={'Large'}
      className={classNames(styles.filtersTrigger)}
      {...rest}
    >
      <FiltersIcon size={55} />
    </RoundButton>
    {isOpen === true && <div className={styless.overlay} onClick={onClick}></div>}
    {/* <Heading size="xsmall">Show filters</Heading> */}
  </div>
);
