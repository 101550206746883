import React from 'react';

import { apiUrls } from '@constants/apiurls';
import projects from '@redux/modules/projects';
import styles from '@components/dashboard/dashboard.module.scss';
import { routesPath } from '@constants/routesPath';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ClientHeader } from '@components/clientHeader/clientHeader';
import clients from '@redux/modules/clients';
import { Spinner } from '@components/loader/loader';
import { ContactsListing } from '@components/contactsListing/contactsListing';

export const ContactsListingContainer = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    data: clientData,
    error: clientError,
    isFetching: clientLoading,
  } = clients.list().useClientsdetailQuery(
    { params: { id: params.clientId }, extendedPath: apiUrls.getClientDetail() },
    {
      skip: !params.clientId,
    },
  );
  const onAdd = () => {
    navigate(routesPath.addContacts(params.clientId));
  };
  return (
    <div className={styles.dashboardWrappper}>
      {clientLoading ? (
        <div className="text-center ">
          <Spinner height="100vh" width="100" />
        </div>
      ) : (
        <ContactsListing
          clients={clientData?.data}
          contacts={clientData?.data.client_contacts}
          onProjectAdd={onAdd}
        />
      )}
    </div>
  );
};
