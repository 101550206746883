import React from 'react';
import coverImage from '../../static/images/hotel-cover-default.png';
import { MdEditAttributes } from 'react-icons/md';
import { MdSettings as SettingsIcon } from 'react-icons/md';
import styles from './clientCard.module.scss';
import { Button } from '../button/button';
import { Dropdown } from '../dropDown/dropDown';
import { Heading } from '../heading/heading';
import { MenuItem } from '../menuItem/menuItem';
import { LogoUploader } from '../form/logoUploader';

export const ClientCard = (props) => {
  const {
    client,
    children,
    isEditable,
    onCoverClick,
    onEdit,
    showSettings,
    standalone,
    onClientRemove,
    extraClass,
    onlogoClick,
    state,
  } = props;

  const getCoverImage = (images) => {
    if (state?.images?.length) {
      return state?.images?.find((img) => img.is_cover);
    } else {
      return images.find((img) => img.is_cover);
    }
  };

  return (
    <div
      className={`client-profile ${extraClass && styles[extraClass]}  ${
        styles.clientProfileWrapper
      }`}
    >
      <>
        <div className={`${styles.clientCover} ${standalone && styles.standalone}`}>
          {client?.client_images && client?.client_images[0] ? (
            <div
              className={styles.imagePreview}
              style={{
                backgroundImage: `url('  ${
                  getCoverImage(client?.client_images)?.image || client.client_images[0].image
                } ')`,
              }}
              title="Preview"
            />
          ) : !(client?.client_images && client?.client_images[0]) ? (
            <div
              className={styles.imagePreview}
              style={{
                backgroundImage: `url('${coverImage}')`,
              }}
              title="Preview"
            ></div>
          ) : isEditable ? (
            <Button inline onClick={onCoverClick}>
              <MdEditAttributes size={25} />
              Upload hotel photos
            </Button>
          ) : null}
        </div>
        <div className={styles.clientLogo}>
          {isEditable ? (
            <div className={`${styles.clientLogoUpload} ${styles.haslogo}`}>
              <LogoUploader name="logo" isIcon={true} />
            </div>
          ) : client?.logo ? (
            <div
              onClick={onlogoClick}
              className={styles.clientLogoLink}
              style={{ backgroundImage: `url("${client?.logo}")` }}
            >
              <img
                src={client?.logo[0]?.image || client?.logo}
                width="110"
                height="110"
                alt={client?.title}
                className={styles.clientLogoImage}
              />
            </div>
          ) : (
            !client?.logo && (
              <div
                className={styles.logoPreview}
                style={{
                  backgroundImage: coverImage,
                }}
                title="Preview"
              ></div>
            )
          )}
        </div>
        <div className={styles.clientBody}>
          <div className={styles.clientNameWrapper}>
            {showSettings && (
              <div className={styles.clientSettingsWrapper}>
                <Dropdown zIndex={16} trigger={<SettingsIcon size={20} />}>
                  {onEdit && <MenuItem onClick={() => onEdit(client)}>Client settings</MenuItem>}
                  {onClientRemove && (
                    <MenuItem onClick={() => onClientRemove(client)}>Remove client</MenuItem>
                  )}
                </Dropdown>
              </div>
            )}
            {!isEditable ? (
              <div onClick={onlogoClick} className={styles.clientNameLink}>
                <Heading className={styles.clientName} size="medium">
                  {client?.title}
                </Heading>
              </div>
            ) : (
              <Heading className={styles.clientName} size="medium">
                {client?.title}
              </Heading>
            )}

            <p className={styles.clientLocation} size="small">
              {client?.city && client?.city + ', '}{' '}
              {client?.country?.title && client?.country?.title}
            </p>
          </div>
        </div>
      </>
      {children}
    </div>
  );
};

export default ClientCard;
