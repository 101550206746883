export const apiUrls = {
  getCountries: () => `/rest/countries/index`,
  getHotelTypes: () => `/rest/client-types/index`,
  getClientDetail: () => `/get`,
  getClientListing: () => `/index`,
  getJobsListing: () => `/index`,
  getColors: () => `/rest/colours/index`,
  getEmploymentTypes: () => `/rest/employment-types/index`,
  getPositionLevels: () => `/rest/position-levels/index`,
  getQualifications: () => `/rest/qualifications/index`,
  getLanguages: () => `/rest/languages/index`,
  getMemberShipGrades: () => `/rest/membership-grades/index`,
  createJob: () => `/create`,
  getJob: () => '/get',
  getJobDuration: () => `/rest/employment-durations/index`,
  deleteJob: () => `/rest/jobs/delete`,
  jobApplicantsSearch: () => `/search`,
  getTags: () => `/index`,
  getProjects: () => `/index`,
  getProjectDetail: () => `/get`,
  getUsers: () => `/index`,
  getPipelines: () => `/index`,
  getPipeline: (id) => `/get`,
  updatePipeline: () => `/update`,
  searchUsers: () => `/search`,
  getDepartments: () => `/rest/departments/autosuggest?limit=100`,
  getMaritalStatus: () => `/rest/marital-statuses/autosuggest?limit=100`,
  getGenders: () => `/rest/genders/autosuggest?limit=100`,
  getUserTags: () => `/rest/user-tags/index`,
  getProject: () => `/get`,
};
