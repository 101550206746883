import classNames from 'classnames';
import styles from '@components/jobForm/jobForm.module.scss';
import stylesJob from '@components/jobIndexCard/JobForm.module.scss';
import { apiUrls } from '@constants/apiurls';
import { useState } from 'react';
import { useEffect } from 'react';
import { JobForm } from '@components/jobForm/jobForm';
import { Heading } from '@components/heading/heading';
import { Header } from '@components/header/header';
import clients from '@redux/modules/clients';
import yupSchemaService from '@services/yupSchemaService';
import dataService from '@services/dataService';
import { fetchAutoCompleteData } from '@services/autoCompleteService';
import jobs from '@redux/modules/jobs';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { routesPath } from '@constants/routesPath';
import { Spinner } from '@components/loader/loader';
import dayjs from 'dayjs';
import { JobHeader } from '@components/jobHeader/jobHeader';

export const JobsFormContainer = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [state, setState] = useState({
    colors: [],
    employmentTypes: [],
    positionLevels: [],
    preferredQualifications: [],
    languages: [],
    membershipGrades: [],
    images: [],
    durations: [],
    clientData: null,
    city: '',
    state: '',
    country_id: '',

    loading: false,
  });

  const {
    data: clientsList,
    isLoading: isLoadingClient,
    error: errorClient,
    isSuccess: successClient,
  } = clients.list().useClientslistQuery({ params: '', extendedPath: apiUrls.getClientListing() });

  const {
    data: jobData,
    error: jobError,
    isLoading: jobLoading,
  } = jobs.list().useJobdetailQuery({
    params: { id: params.jobId },
    extendedPath: apiUrls.getJob(),
    skip: !params.jobId,
  });

  const [updateJob, jobUpdateData] = jobs.form().useJobupdateMutation();

  const [createJob, createJobData] = jobs.form().useJobcreateMutation();

  useEffect(() => {
    getInitailData();
  }, []);

  useEffect(() => {
    if (jobData?.data) {
      setState((prevState) => ({
        ...prevState,
        country_id: jobData?.data?.job?.country_id,
        city: jobData?.data?.job?.city,
        state: jobData?.data?.job?.city,
        images: jobData?.data?.job?.job_images || [],
      }));
    }
  }, jobData);

  useEffect(() => {
    if (jobUpdateData.isSuccess) {
      toast.success('Job Updated Successfully!');
      navigate(routesPath.jobs, { replace: false });
    }
    if (jobUpdateData.isError) {
      toast.error('Failed To Update Job!');
    }
  }, [jobUpdateData]);

  useEffect(() => {
    if (clientsList) {
      let result = dataService.autoCompletionParser('clients', _.cloneDeep(clientsList?.data));
      setState((prevState) => ({ ...prevState, clientData: result }));
    }
  }, [clientsList]);

  const getInitailData = async () => {
    const initailDataList = [
      { url: apiUrls.getColors() },
      { url: apiUrls.getEmploymentTypes() },
      { url: apiUrls.getPositionLevels() },
      { url: apiUrls.getQualifications() },
      { type: 'language', url: apiUrls.getLanguages() },
      { url: apiUrls.getMemberShipGrades() },
      { type: 'duration', url: apiUrls.getJobDuration() },
    ];
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const promises = initailDataList.map((data) => fetchAutoCompleteData(data.type, data.url));
      const response = await Promise.all(promises);

      setState((prevState) => ({
        ...prevState,
        colors: response[0],
        employmentTypes: response[1],
        positionLevels: response[2],
        preferredQualifications: response[3],
        languages: response[4],
        membershipGrades: response[5],
        durations: response[6],
      }));
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (err) {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const submitForm = (values) => {
    let parsedValues = dataService.parseDataForJobCreationApi(values);
    parsedValues.state = state.state;
    parsedValues.city = state.city;
    parsedValues.country_id = state.country_id;
    parsedValues.job_images = state.images.map((img) => {
      if (img) {
        return { url: img.url || img.image };
      }
    });

    if (params.jobId) {
      parsedValues.id = parseInt(params.jobId);
      updateJob(parsedValues);
    } else {
      createJob({ body: parsedValues });
    }
  };

  useEffect(() => {
    if (createJobData?.isSuccess) {
      toast.success('Job Successfully Created!');
      navigate(routesPath.jobs, { replace: false });
    }
    if (createJobData?.error) {
      toast.error('Failed To Post Job!');
    }
  }, [createJobData]);

  return (
    <div>
      <JobHeader />
      {params?.jobId ? (
        <JobHeader
          step={1}
          job={jobData?.data?.job}
          colors={state?.colors}
          jobId={params?.jobId}
          location={location}
        />
      ) : (
        <Header title="New Job Post" avatar={false} />
      )}
      <div className={classNames(styles.page, { [stylesJob.applicantsPage]: params?.jobId })}>
        <div
          className={
            (classNames(styles.matchCompCardContent), 'd-flex align-self-center flex-column')
          }
        >
          <div className={styles.p20}>
            <div className={classNames(styles.sectionLabel)}>
              <Heading size="large">{'Tell us about your job'}</Heading>
            </div>
          </div>
          {state.loading || jobLoading ? (
            <div className="text-center">
              <Spinner height="150" width="150" />
            </div>
          ) : (
            <div className={classNames('px-3', 'px-xs-0')}>
              <JobForm
                colorValue={state?.colors}
                acronymValue={state?.acronymValue}
                colors={state?.colors}
                initialValues={state}
                clients={state?.clientData}
                employmentTypes={state?.employmentTypes}
                positionLevels={state.positionLevels}
                state={state}
                yupSchema={yupSchemaService.jobCreationSchema()}
                jobData={jobData?.data?.job}
                preferredQualifications={state.preferredQualifications}
                languageOptions={state.languages}
                membershipGrades={state.membershipGrades}
                setState={setState}
                submitForm={submitForm}
                isEditing={params.jobId}
                durations={state.durations}
                loading={jobUpdateData?.isLoading || createJobData?.isLoading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
