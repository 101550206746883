import React, { useEffect } from 'react';
import { map, isEmpty, cloneDeep } from 'lodash';
import classNames from 'classnames';

import { SubMenu } from 'components/subMenu/subMenu';
import { Divider } from 'components/divider/divider';
import { MenuItemCard } from 'components/menuItemCard/menuItemCard';
import { MenuItem } from 'components/menuItem/menuItem';
import { MenuScrollable } from 'components/menuScrollable/menuScrollable';
import { Dropdown } from 'components/dropDown/dropDown';
import { avatarOptions } from 'components/avatar/avatar';

// import { avatarSize } from 'redux/modules/ui';

import styles from './projectsDropdown.module.scss';
import projects from '../../redux/modules/projects';
import toast from 'react-hot-toast';

// import { COLUMNS } from 'redux/modules/cards';

export const ProjectsDropdown = ({
  projectsData,
  onClick,
  children,
  disabled,
  zIndex,
  toRight,
  buttonComponent,
  applicant,
  inline,
}) => {
  const avatarSize = 40;

  const [updateProject, updateProjectData] = projects.form().useProjectupdateMutation();

  const addApplicantToPipeline = (stage, project) => {
    let tempProject = {};
    tempProject.id = project.id;
    tempProject.projects_pipeline_stages_users = cloneDeep(project.projects_pipeline_stages_users);

    let index = tempProject.projects_pipeline_stages_users.findIndex(
      (usr) => usr.user_id === applicant?.id,
    );

    if (index >= 0) {
      tempProject.projects_pipeline_stages_users[index] = {
        user_id: applicant.id,
        pipeline_stage_id: stage.id,
        binned: 0,
        title: applicant?.full_name,
      };
    } else {
      tempProject.projects_pipeline_stages_users.push({
        user_id: applicant.id,
        pipeline_stage_id: stage.id,
        binned: 0,
        title: applicant?.full_name,
      });
    }

    updateProject(tempProject);
  };

  useEffect(() => {
    if (updateProjectData?.isSuccess) {
      toast.success('Apllicant Added To Pipeline!');
    }
    if (updateProjectData?.error) {
      toast.error('Failed To Add Applicant To Pipeline!');
    }
  }, [updateProjectData]);

  return (
    <>
      {disabled && children}
      {!disabled && (
        <Dropdown
          className={classNames(styles.projectsDropdownWrapper, { [styles.inline]: true })}
          trigger={children}
          zIndex={zIndex}
          disabled={disabled}
          toRight={toRight}
          buttonComponent={buttonComponent}
        >
          {projectsData?.length > 0 && <Divider size="large">Add to project</Divider>}

          {projectsData?.length < 0 && (
            <>
              <Divider size="large">No projects</Divider>
              <div className={styles.noProjectWarning}>
                Looks like you haven&#039;t setup any projects
              </div>
            </>
          )}

          <MenuScrollable>
            {map(projectsData, (project, index) => (
              <div className={classNames('project', styles.applicantProjects)}>
                <SubMenu
                  className={classNames(styles.projectsPipelineWrapper)}
                  key={index}
                  zIndex={13}
                  menuItem={
                    <MenuItemCard
                      key={`project-${project.id}`}
                      avatar={{ ...avatarOptions(project, 'project'), size: avatarSize.medium }}
                      name={project.title}
                      subhead={project.client && project.client.title}
                    />
                  }
                >
                  <Divider size="large">Select project stage</Divider>
                  <div className={classNames('project', styles.applicantProjects)}>
                    <MenuItemCard
                      size={44}
                      key={`project-${project.id}`}
                      avatar={{ ...avatarOptions(project, 'project'), size: avatarSize.medium }}
                      name={project.title}
                      subhead={project.client && project.client.title}
                    />
                  </div>

                  {map(
                    project?.pipeline?.pipeline_stages,
                    (stage) =>
                      !isEmpty(stage) && (
                        // <MenuItem
                        //   key={`move-to-${index}-${stage.title}`}
                        //   // onClick={() => onClick(project, column)}
                        // >
                        //   {/* {project.pipeline[column]} */}
                        //   {stage.title}
                        // </MenuItem>
                        <div className="px-1">
                          <div className={classNames('project', styles.applicantProjects)}>
                            <MenuItemCard
                              size={44}
                              key={`project-${stage.id}`}
                              // avatar={{ ...avatarOptions(project, 'project'), size: avatarSize.medium }}
                              name={stage.title}
                              onClick={() => addApplicantToPipeline(stage, project)}
                              // subhead={project.client && project.client.title}
                            />
                          </div>
                        </div>
                      ),
                  )}
                </SubMenu>
              </div>
            ))}
          </MenuScrollable>
        </Dropdown>
      )}
    </>
  );
};
