import React from 'react';
import { Link } from 'react-router-dom';

import styles from './roundButton.module.scss';

export const RoundButton = ({ size, onClick, link, disabled, children, className, theme }) => (
  <div
    className={`float-button  ${styles[size]} ${styles[theme]} ${styles.buttonWrapper} ${
      disabled ? styles.disabled : ''
    } ${className ? className : ''}`}
    onClick={onClick}
  >
    {link ? (
      <Link to={link} onClick={onClick}>
        {children}
      </Link>
    ) : (
      children
    )}
  </div>
);

RoundButton.defaultProps = {
  theme: 'blue',
};
