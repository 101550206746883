import React from 'react';

import { Header } from 'components/header/header';
import { NavItem } from 'components/nav/navItem';
import { Labeled } from 'components/labeled/labeled';
import { JobLabel } from 'components/jobLabel/jobLabel';

import classNames from 'classnames';
import styles from './jobHeader.module.scss';
import { routesPath } from '../../constants/routesPath';

export const JobHeader = ({
  newEntry,
  children,
  onCancel,
  customButtons,
  job,
  colors,
  jobId,
  client,
  clientLogo,
  titleLeft = 'New Job Post',
  active,
  location,
  locationOptions,
}) => (
  <>
    {newEntry && (
      <Header
        titleLeft={titleLeft}
        newEntry={newEntry}
        onCancelButton={onCancel}
        customButtons={customButtons}
      >
        {children}
      </Header>
    )}
    {job && (
      <Header
        doubleAvatar={
          <div className={classNames(styles.doubleAvatarWrapper)}>
            <JobLabel
              colors={colors}
              job={job}
              client={client}
              clientLogo={clientLogo}
              showClient={false}
              classIndicator="jobHeaderIndicator"
            />
          </div>
        }
        // link={routes.editJob(jobId)}
        newEntry={newEntry}
        customButtons={customButtons}
      >
        <NavItem
          link={routesPath?.jobEdit(jobId)}
          active={location?.pathname === routesPath.jobEdit(jobId)}
        >
          <Labeled>Edit post</Labeled>
        </NavItem>
        <NavItem
          link={routesPath?.jobApplicants(jobId)}
          active={location?.pathname === routesPath.jobApplicants(jobId)}
        >
          <Labeled toCount={0}>Applicants</Labeled>
        </NavItem>
        {/* <NavItem link={ routes.jobMatches(jobId) } active={ active === locationOptions.locationMatches }>
              <Labeled toCount={ 0 }>Matches</Labeled>
            </NavItem> */}

        {children}
      </Header>
    )}
  </>
);
