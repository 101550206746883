import { useState } from 'react';
import classNames from 'classnames';
import { JobFormTitle } from '../jobFormTitle/jobFormTitle';
import { JobFormDescription } from '../jobFormDescription/jobFormDescription';
import { JobFormCandidatesReq } from '../jobFormCandidatesReq/jobFormCandidatesReq';
import { JobFormCompanyDetails } from '../jobFormCompanyDetails/jobFormCompanyDetails';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dataService from '../../services/dataService';
import { JobPostStatus } from '../jobPostStatus/jobPostStatus';
import { JobFormImageBranding } from '../jobFormImageBranding/jobFormImageBranding';
import styles from './jobForm.module.scss';
import stylesForms from '../formColumns/forms.module.scss';
import { useCallback } from 'react';

export const JobForm = (props) => {
  const {
    handleSubmit,
    acronymValue,
    colorValue,
    isSubmitting,
    isEditing,
    clients,
    clientValue,
    submitForm,
    selectedClient,
    setFormFieldValue,
    job,
    employmentTypes,
    contractEmploymentType,
    positionLevels,
    languageOptions,
    preferredQualifications,
    membershipGrades,
    durations,
    imageControls,
    changeHandler,
    colors,
    yupSchema,
    jobData,
    state,
    loading,
    setState,
  } = props;

  const [initialValues] = useState(() => {
    return dataService.parseFormDataForJobEdit(jobData);
  });
  const form = useForm({
    resolver: yupResolver(yupSchema),
    mode: 'all',
    defaultValues: initialValues,
    shouldUnregister: true,
  });

  const onSubmit = (values) => {
    submitForm(values);
  };

  const handleClientChange = useCallback(
    (clientId) => {
      let selectedCandidate = clients?.find((client) => client.id === clientId);
      if (selectedCandidate) {
        setState((prevState) => ({
          ...prevState,
          city: selectedCandidate.city,
          state: selectedCandidate.state,
          country_id: selectedCandidate.country_id,
        }));
      }
    },
    [state, setState],
  );

  const handleDurationType = (id, setValue) => {
    if (id == 3) {
      setValue('employment_duration_id', '', { shouldValidate: true });
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className={classNames('form', stylesForms.form)}>
        <div className="container-fluid p-0">
          <div className="row w-100 m-0">
            <div className={classNames('col-lg-8', 'ps-0', 'pe-0', 'pe-lg-3')}>
              <JobFormTitle acronymValue={acronymValue} color={colorValue} colors={colors} />
              <JobFormDescription
                employmentTypes={employmentTypes}
                positionLevels={positionLevels}
                durations={durations}
                handleDurationType={handleDurationType}
              />
              <JobFormCandidatesReq
                preferredQualifications={preferredQualifications}
                languageOptions={languageOptions}
                membershipGrades={membershipGrades}
                state={state}
                setState={setState}
                handleChange={handleClientChange}
                clients={clients}
              />
              <JobFormCompanyDetails
                clientValue={clientValue}
                clients={clients}
                selectedClient={selectedClient}
                setFormFieldValue={setFormFieldValue}
                isEditing={isEditing}
                handleClientChange={handleClientChange}
              />
              <JobFormImageBranding state={state} setState={setState} />
            </div>
            <div className="col-lg-4 p-0">
              <JobPostStatus showCreate={true} isEditing={isEditing} loading={loading} />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
