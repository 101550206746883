import React, { useEffect, useState } from 'react';
import { ClientForm } from '@components/clientForm/clientForm';
import { Wizard } from '@components/wizard/wizard';
import { MdLocationCity as HotelIcon } from 'react-icons/md';
import { fetchAutoCompleteData } from '@services/autoCompleteService';
import { apiUrls } from '@constants/apiurls';
import clients from '@redux/modules/clients';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import yupSchemaService from '@services/yupSchemaService';
import { Spinner } from '@components/loader/loader';
import { routesPath } from '@constants/routesPath';
// import { ClientHeader } from '@components/clientHeader/clientHeader';
import { useDispatch } from 'react-redux';
import { updateWizardStep } from '@redux/common/commonSlice';

export const ClientCreationContainer = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    countryOptions: [],
    clientTypeOptions: [],
    images: [],
  });

  const [clientCreation, clientCreationData] = clients.form().useClientscreateMutation();
  const [clientUpdate, clientUpdateData] = clients.form().useClientsupdateMutation();
  const [clientInlineUpdate, clientInlineUpdateData] = clients.form().useClientsupdateMutation();

  useEffect(() => {
    dispatch(
      updateWizardStep({
        step: 0,
      }),
    );
    getInitialData();
  }, []);

  const {
    data: clientData,
    error: clientError,
    isFetching: clientLoading,
  } = clients
    .list()
    .useClientsdetailQuery(
      { params: { id: params.clientID }, extendedPath: apiUrls.getClientDetail() },
      { skip: !params.clientID },
    );

  useEffect(() => {
    if (clientCreationData.isSuccess) {
      toast.success('Client Created Successfully!');

      if (params?.clientID) {
        navigate(routesPath.addContacts(params?.clientID || clientCreationData?.data?.id), {
          replace: false,
        });
      } else {
        navigate(routesPath.addContacts(clientCreationData?.data?.data?.id), {
          replace: false,
        });
      }
    } else if (clientCreationData.isError) {
      toast.error(clientCreationData.error);
    }
  }, [clientCreationData]);

  useEffect(() => {
    if (clientData?.data?.client_images?.length) {
      setState((prevState) => ({ ...prevState, images: clientData.data.client_images }));
    }
  }, [clientData]);

  useEffect(() => {
    if (clientUpdateData.isSuccess) {
      toast.success('Client Updated Successfully!');
      navigate(routesPath.addContacts(params?.clientID), { replace: false });
    } else if (clientUpdateData.isError) {
      toast.error('Failed to update');
    }
  }, [clientUpdateData]);

  useEffect(() => {
    if (clientInlineUpdateData.isSuccess) {
      toast.success('Client Updated Successfully!');
      //navigate(routesPath.editClient(params?.clientID), { replace: false });
    } else if (clientInlineUpdateData.isError) {
      toast.error('Failed to update');
    }
  }, [clientInlineUpdateData]);

  useEffect(() => {
    if (clientError) {
      toast.error(clientError.status);
    }
  }, [clientError]);

  const getInitialData = async () => {
    try {
      const autoCompletedFields = [
        { type: 'hotel', url: apiUrls.getHotelTypes() },
        { type: 'country', url: apiUrls.getCountries() },
      ];
      const promises = autoCompletedFields.map((field) =>
        fetchAutoCompleteData(field.type, field.url),
      );

      const response = await Promise.all(promises);
      setState((prevState) => ({
        ...prevState,
        countryOptions: response[1],
        clientTypeOptions: response[0],
        base: response[2],
      }));
    } catch (err) {}
  };

  const createClient = (values) => {
    values.body.client_images = [...state.images];
    if (values.body.vimeo_url) {
      values.body.client_images.push({
        client_id: parseInt(params.clientID),
        image: values.body.vimeo_url,
        type: 2,
      });
      delete values.body.vimeo_url;
    }
    if (values.body.youtube_url) {
      values.body.client_images.push({
        client_id: parseInt(params.clientID),
        image: values.body.youtube_url,
        type: 2,
      });
      delete values.body.youtube_url;
    }

    if (params.clientID) {
      clientUpdate(values.body);
    } else {
      clientCreation(values);
    }
  };

  const updateClient = (values) => {
    console.log(values.body);
    clientInlineUpdate(values.body);
  };

  return (
    <>
      {clientLoading ? (
        <div className="text-center ">
          <Spinner height="100vh" width="100" />
        </div>
      ) : (
        <div className="client-creation-screen">
          <div className="">
            <div className="">
              <div className="">
                <Wizard
                  step={1}
                  title={
                    clientData
                      ? 'Edit Client Profile'
                      : "Let's get started\n Create a client profile."
                  }
                  icon={<HotelIcon size={25} />}
                >
                  <ClientForm
                    state={state}
                    setState={setState}
                    ClientTypeOptions={state.clientTypeOptions}
                    countryOptions={state.countryOptions}
                    createClient={createClient}
                    updateClient={updateClient}
                    loading={
                      clientCreationData.isLoading ||
                      clientUpdateData?.isLoading ||
                      clientInlineUpdateData?.isLoading
                    }
                    clientData={clientData}
                    yupSchema={yupSchemaService.clientCreationSchema()}
                    isEdit={Boolean(params.clientID) && !clientError}
                  />
                </Wizard>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
