import { MdAttachment as AttachmentIcon } from 'react-icons/md';
import { MdEmail as EmailIcon } from 'react-icons/md';
import classNames from 'classnames';

import styles from './kanban.module.scss';
import { Avatar } from '../avatar/avatar';
import { Heading } from '../heading/heading';
import { useDispatch } from 'react-redux';
import { views } from '../../constants/views';
import { handleViewsData } from 'redux/common/commonSlice';

export const KanbanCard = ({
  title,
  user,
  pipeline_stage_id,
  id,
  description,
  checkBoxAction,
  expertise_string,
}) => {
  console.log('user====>', user);
  const dispatch = useDispatch();

  return (
    <div className={styles.kanbanCard}>
      <div
        className="d-flex justify-content-start gap-3"
        onClick={() => {
          dispatch(
            handleViewsData({
              talent: { user: user },
              currentView: views.talentEmail,
            }),
          );
        }}
      >
        {checkBoxAction}
        <div className="d-flex align-items-center">
          <Avatar
            src={user.avatar}
            name={user.full_name}
            className={classNames(styles.avatar, styles.img_avatar)}
            size={40}
          />
        </div>
        <div className="d-flex">
          <div className={(styles.kanbanCardHead, 'd-flex justify-content-center flex-column')}>
            <Heading size="small" className={styles.kanbanCardHeading}>
              {user?.full_name}
            </Heading>

            <div className={styles.kanbanCardDescription}>{user?.expertise_string}</div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end gap-2">
        <div>
          <AttachmentIcon className={styles.attachment} size={20} />
        </div>
        <div>
          <EmailIcon className={styles.inbox} size={20} />
        </div>
      </div>
    </div>
  );
};
