import React from 'react';
import classNames from 'classnames';

import { Heading } from '../heading/heading';

import styles from './wizard.module.scss';

export const Wizard = ({ step, action, icon, title, children }) => (
  <div className={styles.wizardPageWrapper}>
    <div className={classNames(styles.wizardWrapper, { [styles[`wizardStep-${step}`]]: step })}>
      <div className={styles.wizardTitle}>
        <span className={styles.iconContainer}>{icon}</span>
        <Heading size="large">{title}</Heading>
      </div>
      <div className={styles.wizardContainer}>{children}</div>
      {action && <div className={styles.skipStep}>{action}</div>}
    </div>
  </div>
);
