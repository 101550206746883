import React from 'react';
import { Button } from '../button/button';
import classNames from 'classnames';
import styles from './jobImageLogoDescription.module.scss';
import { useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';
import logoDefault from '../../static/images/hotel-logo-default.png';
import { LogoUploader } from '../form/logoUploader';

export const JobImageLogoDescription = (props) => {
  const { job } = props;

  const logo = useWatch({ name: 'logo' });

  return (
    <div className={classNames(styles.inputWrapper)}>
      <div className={classNames(styles.logoDisplayWrapper)}>
        <img
          src={logo || logoDefault}
          width="110"
          height="110"
          alt={job?.name}
          className={classNames(styles.jobLogoImage)}
        />
      </div>
      <div className={classNames(styles.uploadButtonContainer)}>
        <div className={classNames(styles.uploadContentWrapper)}>
          <span className={classNames(styles.inputTitle)}>
            Add a company logo
            <div className={classNames(styles.textSmall, 'mb-0')}>
              {'For best results, upload square images.'}
            </div>
            <div className={classNames(styles.textSmall)}>
              {'Jpg, Png, or Gif file types only.'}
            </div>
          </span>
        </div>
        <div className={classNames(styles.uploadContentWrapper)}>
          <LogoUploader submit inline size={'small'} name="logo" noImg={true} isIcon={false} />
        </div>
      </div>
    </div>
  );
};
