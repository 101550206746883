import React from 'react';
import { NavLink } from 'react-router-dom';
import { size } from 'lodash';

import { BaseCard } from '../baseCard/baseCard';
import Role from '../role/role';
import { MdOutlineWc as CandidatesIcon } from 'react-icons/md';
import { avatarOptions } from '../avatar/avatar';

import styles from './recruiterCard.module.scss';
import { routesPath } from '../../constants/routesPath';

export const RecruiterCard = ({ client, recruiter, onRemove, onEdit, color, onClick }) => (
 <>
    {recruiter ? (
      <BaseCard
        item={recruiter}
        color={color}
        avatar={{ ...avatarOptions(recruiter, 'recruiter'), size: 40 }}
        title={recruiter?.full_name}
        subhead={recruiter?.role}
        // link={routes.projectPipeline(project.client.id, project.id)}
        onEdit={true}
        size={'medium'}
        onRemove={onRemove}
        onClick={onClick}
      >
        <div className={styles.contentWrapper}>
          {/* <NavLink
            className={styles.candidates}
            to={routesPath.dashboard}
            // to={routes.projectPipeline(project.client.id, project.id)}
          >
            <CandidatesIcon size={20} /> {size(project.talent)} Candidates
          </NavLink> */}
          {/* <Role className={project.minimum_level}>{project.minimum_level}</Role> */}
        </div>
      </BaseCard>
    ) : (
      ''
    )}
  </>
);

// ProjectCard.propTypes = {
//   project: React.PropTypes.object.isRequired,
// };
