import React from 'react';
import { Heading } from 'components/heading/heading';
import { Button } from 'components/button/button';
import './RolesAndPermissions.scss';
import { useState, useEffect } from 'react';
import { MdAdd as AddIcon } from 'react-icons/md';
import axios from 'axios';
import { baseUrl } from 'constants/baseUrl';
import storageService from 'services/storageService';
import RolesAndPermissionsSection from 'components/rolesAndPermissionsSection/RolesAndPermissionsSection';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
const RolesAndPermissions = () => {
  const [visible, setVisible] = useState({ openAdd: false, openUpdate: false, type: '' });
  const [data, setData] = useState([]);
  const [show, setShow] = useState(true);
  const [value, setValue] = useState({
    title: '',
    create_clients: false,
    edit_clients: true,
    post_jobs: true,
    create_job_templates: false,
    edit_job_templates: true,
    create_projects: false,
    edit_projects: false,
    create_pipelines: true,
    manage_pipeline_candidates: true,
    create_tasks: true,
    view_tasks: false,
    assign_tasks: true,
    schedule_calendar: true,
    view_calendars: false,
    schedule_member_calendars: true,
    configure_settings: false,
    manage_billing: true,
  });
  const [id, setId] = useState('');
  const handleChange = (e) => {
    if (e.target.type === 'text') {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
    } else {
      setValue({
        ...value,
        [e.target.name]: e.target.checked,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);
  console.log('data', data);

  const getData = async () => {
    await axios
      .get(`https://api.holedo.com/rest/company-roles/index`, {
        headers: {
          common: {
            AuthApi: `Bearer ${storageService.getToken()}`,
          },
        },
      })
      .then((res) => {
        setData(res.data.data);
        console.log('res', res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const dataSubmit = async () => {
    await axios
      .post(
        `https://api.holedo.com/rest/company-roles/create`,
        {
          ...value,
          create_clients: value.create_clients ? 1 : 0,
          edit_clients: value.edit_clients ? 1 : 0,
          post_jobs: value.post_jobs ? 1 : 0,
          create_job_templates: value.create_job_templates ? 1 : 0,
          edit_job_templates: value.edit_job_templates ? 1 : 0,
          create_projects: value.create_projects ? 1 : 0,
          edit_projects: value.edit_projects ? 1 : 0,
          create_pipelines: value.create_pipelines ? 1 : 0,
          manage_pipeline_candidates: value.manage_pipeline_candidates ? 1 : 0,
          create_tasks: value.create_tasks ? 1 : 0,
          view_tasks: value.view_tasks ? 1 : 0,
          assign_tasks: value.assign_tasks ? 1 : 0,
          schedule_calendar: value.schedule_calendar ? 1 : 0,
          view_calendars: value.view_calendars ? 1 : 0,
          schedule_member_calendars: value.schedule_member_calendars ? 1 : 0,
          configure_settings: value.configure_settings ? 1 : 0,
          manage_billing: value.manage_billing ? 1 : 0,
        },
        {
          headers: {
            common: {
              AuthApi: `Bearer ${storageService.getToken()}`,
            },
          },
        },
      )
      .then((res) => {
        console.log('res', res);
        setId(res.data.data.id);
        setVisible({ openAdd: false, openUpdate: false });
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(id);
  const dataUpdate = async () => {
    await axios
      .post(
        `https://api.holedo.com/rest/company-roles/update`,
        {
          ...value,
          create_clients: value.create_clients ? 1 : 0,
          edit_clients: value.edit_clients ? 1 : 0,
          post_jobs: value.post_jobs ? 1 : 0,
          create_job_templates: value.create_job_templates ? 1 : 0,
          edit_job_templates: value.edit_job_templates ? 1 : 0,
          create_projects: value.create_projects ? 1 : 0,
          edit_projects: value.edit_projects ? 1 : 0,
          create_pipelines: value.create_pipelines ? 1 : 0,
          manage_pipeline_candidates: value.manage_pipeline_candidates ? 1 : 0,
          create_tasks: value.create_tasks ? 1 : 0,
          view_tasks: value.view_tasks ? 1 : 0,
          assign_tasks: value.assign_tasks ? 1 : 0,
          schedule_calendar: value.schedule_calendar ? 1 : 0,
          view_calendars: value.view_calendars ? 1 : 0,
          schedule_member_calendars: value.schedule_member_calendars ? 1 : 0,
          configure_settings: value.configure_settings ? 1 : 0,
          manage_billing: value.manage_billing ? 1 : 0,
        },
        {
          headers: {
            common: {
              AuthApi: `Bearer ${storageService.getToken()}`,
            },
          },
        },
      )
      .then((res) => {
        console.log('res', res);
        setVisible({ openUpdate: false, openAdd: false });
        getData();
        setShow(true);
        // AccordionItemState({expended:false})
        console.log('openUpdate', visible.openUpdate);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const dataDelete = async () => {
    await axios
      .post(
        `https://api.holedo.com/rest/company-roles/delete`,
        {
          id: value.id,
        },
        {
          headers: {
            common: {
              AuthApi: `Bearer ${storageService.getToken()}`,
            },
          },
        },
      )
      .then((res) => {
        console.log('res', res);
        setVisible({ openUpdate: false, openAdd: false }); // Edit(value.id)
        getData();
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Edit = (id) => {
    let a = data.find((x) => x.id === id);
    setValue(a);
    setVisible({ openUpdate: true, openAdd: false });
  };
  console.log('setShoe', show);
  return (
    <>
      <Heading size={'large'}>Roles and Permissions</Heading>
      <p>
        Assign predifined roles to your team members. These roles represent the permissions and
        access, rights which that user will be granted. Define your roles and permissions for each
        role below
      </p>
      <div className="roles">
        <Accordion
          style={{ border: 'none' }}
          allowZeroExpanded
          onChange={(e) => (e.length === 0 ? setShow(true) : setShow(false))}
        >
          {data.map((x, index) => (
            <AccordionItem
              onClick={() => {
                Edit(x.id);
              }}
              style={{ margin: '10px 0 ', border: 'none' }}
            >
              <AccordionItemHeading style={{ margin: '0' }}>
                <AccordionItemButton className="first">
                  {/* <AccordionItemState>
                    {({ expanded }) => (expanded ? setShow(false) : setShow(true))}
                </AccordionItemState> */}
                  <h5>{x.title} </h5>
                  <h5 style={{ color: '#32a3fd' }}>Edit permissions</h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel style={{ padding: '0', margin: '0' }}>
                {visible.openUpdate == true ? (
                  <RolesAndPermissionsSection
                    visible={visible}
                    handleChange={handleChange}
                    value={value}
                    dataSubmit={dataSubmit}
                    dataUpdate={dataUpdate}
                    dataDelete={dataDelete}
                  />
                ) : null}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
        {visible.openAdd == false ? (
          show ? (
            <div
              className="button"
              style={{ background: '#E5F5FC' }}
              onClick={() => {
                setVisible({ openAdd: true, openUpdate: false, type: 'new' });
                setValue({ title: '' });
              }}
            >
              <AddIcon style={{ marginRight: '10px' }} />
              Add user role
            </div>
          ) : null
        ) : null}
        {visible.openAdd == true ? (
          <RolesAndPermissionsSection
            visible={visible}
            handleChange={handleChange}
            value={value}
            dataSubmit={dataSubmit}
            dataUpdate={dataUpdate}
            dataDelete={dataDelete}
          />
        ) : null}
      </div>
    </>
  );
};

export default RolesAndPermissions;
