import React from 'react';
import classNames from 'classnames';
import { AiOutlineCaretDown as SettingsIcon } from 'react-icons/ai';
import { MenuItem } from '../menuItem/menuItem';
import { Dropdown } from '../dropDown/dropDown';
import { CardTitle } from '../cardTitle/cardTitle';

import styles from './baseCard.module.scss';

export const BaseCard = ({
  item,
  size,
  onRemove,
  onEdit,
  children,
  isStandalone,
  onClick,
  color,
  isTeamCard,

  ...rest
}) => {
  return (
    <div
      className={classNames(
        'base-card',
        { [styles[size]]: size },
        styles.card,
        styles.cardWrapper,
        {
          [styles.isStandalone]: isStandalone,
        },
        {
          [styles.isTeamCard]: isTeamCard,
        },
      )}
      // onClick={() => {
      //   onClick && onClick();
      // }}
    >
      <div className={classNames({ [styles.headerWrapper]: !isTeamCard })}>
        <CardTitle color={color} size={size} {...rest} />
        {(onEdit || onRemove) && (
          <div  className={classNames(styles.settingsLinkWrapper)}>
            <Dropdown trigger={<SettingsIcon size={20} />}>
            {/* {onEdit && <MenuItem onClick={() => onEdit(item)}>Edit</MenuItem>}
            {onRemove && <MenuItem onClick={() => onRemove(item)}>Remove</MenuItem>} */}
            <MenuItem onClick={() => onRemove(item)}>View Activity</MenuItem>
            <MenuItem onClick={() => onRemove(item)}>Edit Profile</MenuItem>
            <MenuItem onClick={() => onRemove(item)}>Archive</MenuItem>
            </Dropdown>
          </div>
       )}
      </div>
      {children}
    </div>
  );
};

BaseCard.defaultProps = {
  isStandalone: true,
  size: 'large',
};
