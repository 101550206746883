import React from 'react';
import { Avatar } from 'components/avatar/avatar';

import { Heading } from 'components/heading/heading';
import { Button } from 'components/button/button';

import styles from './profileCard.module.scss';

export const ProfileCard = ({
  firstHeading,
  secondHeading,
  profileUrl,
  thirdHeading,
  label,
  avatar,
  children,
  slug,
}) => {
  return (
    <div className={styles.profile}>
      <Avatar {...avatar} className={styles.avatar} size={100} />
      <br />
      <br />
      <Heading size="medium" className={styles.firstHeading}>
        {firstHeading}
        <small>{label}</small>
      </Heading>
      <Button
        style={{ width: '170px', margin: 'auto' }}
        // onClick={() => window.open(profileUrl, '_blank')}
        size="small"
        submit
      >
        <a
          style={{ color: 'white', textDecoration: 'none' }}
          href={`https://profile.holedo.com/?u=${slug}`}
          target={'_blank'}
        >
          Visit Holedo Profile
        </a>
      </Button>
      <Button
        style={{ width: '170px', margin: 'auto', marginTop: '8px' }}
        // onClick={() => window.location.assign(profileUrl, '_blank')}
        size="small"
        submit
      >
        <a
          style={{ color: 'white', textDecoration: 'none' }}
          href={`https://holedo.im/#/${slug}:holedo.com`}
          target={'_blank'}
        >
          Chat
        </a>
      </Button>
      <p className={styles.secondHeading}>{secondHeading}</p>
      <p className={styles.thirdHeading}>{thirdHeading}</p>
      <div className={styles.profileActions}>{children}</div>
    </div>
  );
};
