import React from 'react';
import { Heading } from 'components/heading/heading';
import { Button } from 'components/button/button';
import './EditTeam.scss';
import { useState, useEffect } from 'react';
import { MdAdd as AddIcon } from 'react-icons/md';
import axios from 'axios';
import { baseUrl } from 'constants/baseUrl';
import storageService from 'services/storageService';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
const EditTeam = () => {
  const [visible, setVisible] = useState({ openAdd: false, openUpdate: false, type: '' });
  const [data, setData] = useState([]);
  const [show, setShow] = useState(true);
  const [value, setValue] = useState({
    title: '',
    create_clients: false,
    edit_clients: true,
    post_jobs: true,
    create_job_templates: false,
    edit_job_templates: true,
    create_projects: false,
    edit_projects: false,
    create_pipelines: true,
    manage_pipeline_candidates: true,
    create_tasks: true,
    view_tasks: false,
    assign_tasks: true,
    schedule_calendar: true,
    view_calendars: false,
    schedule_member_calendars: true,
    configure_settings: false,
    manage_billing: true,
  });
  const [id, setId] = useState('');
  const handleChange = (e) => {
    if (e.target.type === 'text') {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
    } else {
      setValue({
        ...value,
        [e.target.name]: e.target.checked,
      });
    }
  };

  const roles = [
    {
      name: 'post_jobs',
      h5: 'Post Jobs',
      p: 'Has the ability to post jobs',
    },
    {
      name: 'create_job_templates',
      h5: 'Can save job templates',
      p: 'Has the ability to create and save job templates',
    },
    {
      name: 'edit_job_templates',
      h5: 'Can edit job templates',
      p: 'Has the ability to edit existing job templates',
      b: 'BorderNone',
    },
  ];
  useEffect(() => {
    getData();
  }, []);
  console.log('data', data);

  const getData = async () => {
    await axios
      .get(`https://api.holedo.com/rest/projects/index`, {
        headers: {
          common: {
            AuthApi: `Bearer ${storageService.getToken()}`,
          },
        },
      })
      .then((res) => {
        setData(res.data);
        console.log('res', res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const dataSubmit = async () => {
  //   await axios
  //     .post(
  //       `https://api.holedo.com/rest/company-roles/create`,
  //       {
  //         ...value,
  //         create_clients: value.create_clients ? 1 : 0,
  //         edit_clients: value.edit_clients ? 1 : 0,
  //         post_jobs: value.post_jobs ? 1 : 0,
  //         create_job_templates: value.create_job_templates ? 1 : 0,
  //         edit_job_templates: value.edit_job_templates ? 1 : 0,
  //         create_projects: value.create_projects ? 1 : 0,
  //         edit_projects: value.edit_projects ? 1 : 0,
  //         create_pipelines: value.create_pipelines ? 1 : 0,
  //         manage_pipeline_candidates: value.manage_pipeline_candidates ? 1 : 0,
  //         create_tasks: value.create_tasks ? 1 : 0,
  //         view_tasks: value.view_tasks ? 1 : 0,
  //         assign_tasks: value.assign_tasks ? 1 : 0,
  //         schedule_calendar: value.schedule_calendar ? 1 : 0,
  //         view_calendars: value.view_calendars ? 1 : 0,
  //         schedule_member_calendars: value.schedule_member_calendars ? 1 : 0,
  //         configure_settings: value.configure_settings ? 1 : 0,
  //         manage_billing: value.manage_billing ? 1 : 0,
  //       },
  //       {
  //         headers: {
  //           common: {
  //             AuthApi: `Bearer ${storageService.getToken()}`,
  //           },
  //         },
  //       },
  //     )
  //     .then((res) => {
  //       console.log('res', res);
  //       setId(res.data.data.id);
  //       setVisible({ openAdd: false, openUpdate: false });
  //       getData();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // console.log(id);
  // const dataUpdate = async () => {
  //   await axios
  //     .post(
  //       `https://api.holedo.com/rest/company-roles/update`,
  //       {
  //         ...value,
  //         create_clients: value.create_clients ? 1 : 0,
  //         edit_clients: value.edit_clients ? 1 : 0,
  //         post_jobs: value.post_jobs ? 1 : 0,
  //         create_job_templates: value.create_job_templates ? 1 : 0,
  //         edit_job_templates: value.edit_job_templates ? 1 : 0,
  //         create_projects: value.create_projects ? 1 : 0,
  //         edit_projects: value.edit_projects ? 1 : 0,
  //         create_pipelines: value.create_pipelines ? 1 : 0,
  //         manage_pipeline_candidates: value.manage_pipeline_candidates ? 1 : 0,
  //         create_tasks: value.create_tasks ? 1 : 0,
  //         view_tasks: value.view_tasks ? 1 : 0,
  //         assign_tasks: value.assign_tasks ? 1 : 0,
  //         schedule_calendar: value.schedule_calendar ? 1 : 0,
  //         view_calendars: value.view_calendars ? 1 : 0,
  //         schedule_member_calendars: value.schedule_member_calendars ? 1 : 0,
  //         configure_settings: value.configure_settings ? 1 : 0,
  //         manage_billing: value.manage_billing ? 1 : 0,
  //       },
  //       {
  //         headers: {
  //           common: {
  //             AuthApi: `Bearer ${storageService.getToken()}`,
  //           },
  //         },
  //       },
  //     )
  //     .then((res) => {
  //       console.log('res', res);
  //       setVisible({ openUpdate: false, openAdd: false });
  //       getData();
  //       setShow(true);
  //       // AccordionItemState({expended:false})
  //       console.log('openUpdate', visible.openUpdate);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // const dataDelete = async () => {
  //   await axios
  //     .post(
  //       `https://api.holedo.com/rest/company-roles/delete`,
  //       {
  //         id: value.id,
  //       },
  //       {
  //         headers: {
  //           common: {
  //             AuthApi: `Bearer ${storageService.getToken()}`,
  //           },
  //         },
  //       },
  //     )
  //     .then((res) => {
  //       console.log('res', res);
  //       setVisible({ openUpdate: false, openAdd: false }); // Edit(value.id)
  //       getData();
  //       setShow(true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // const Edit = (id) => {
  //   let a = data.find((x) => x.id === id);
  //   setValue(a);
  //   setVisible({ openUpdate: true, openAdd: false });
  // };
  // console.log('setShoe', show);
  return (
    <div>
      <div className="createTeam">
        <div className="topHeading">
          <label className="new">New</label>
          <label style={{ fontSize: '24px' }}>Create a new team</label>
        </div>
        <div className="teamDetails">
          <label style={{ fontSize: '18px', marginBottom: '10px' }}>Team details</label>
          <div className="search">
            <label>Team Name</label>
            <input
              type={'text'}
              name="teamName"
              // value={value.title}
              onChange={handleChange}
              placeholder="Team Name"
            />
          </div>
          <div className="search">
            <label>Team Color</label>
            <select
              type={'text'}
              name="teamColor"
              // value={value.title}
              onChange={handleChange}
              placeholder="Team Select"
            >
              <option value={''}>Green</option>
              <option value={''}>Red</option>
              <option value={''}>Blue</option>
              <option value={''}>Orange</option>
              <option value={''}>Purple</option>
            </select>
          </div>
        </div>
        <div className="rolesSection">
          <div className="rolesHeadimg">
            <label style={{ fontSize: '18px' }}>Add team members to your team</label>
            <label style={{ fontSize: '16px' }}>
              Select from the account members below to add members to this team.
            </label>
          </div>
          <div className="third">
            {roles.map((y) => (
              <div className={`second ${y.b}`}>
                <div className={'addRecruiter'}>
                  <div className="img"></div>
                  {/* <img src={''}/> */}
                  <div className="forth">
                    <h5>{y.h5}</h5>
                    <p>{y.p}</p>
                  </div>
                </div>
                <label className="switch">
                  <input
                    type="checkbox"
                    name={y.name}
                    checked={value[y.name]}
                    onChange={handleChange}
                  />
                  <span className="slider round">
                    {value[y.name] ? (
                      <span className="yes">YES</span>
                    ) : (
                      <span className="no">NO</span>
                    )}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="rolesSection">
          <div className="rolesHeadimg">
            <label style={{ fontSize: '18px' }}>Client and project access</label>
            <label style={{ fontSize: '16px' }}>
              Select which projects this team has access to.
            </label>
          </div>
          <div className="third">
            <div className="addProjects">
              <label style={{ padding: '0.2rem 0.8rem' }}>Clients and projects</label>
              <label style={{ borderLeft: '2px solid #b7b7b7', padding: '0.2rem 0.8rem' }}>
                Add to projects?
              </label>
            </div>
            {roles.map((y) => (
              <div className={`second ${y.b}`}>
                <div className={'addRecruiter'}>
                  <div className="imgSquare"></div>
                  {/* <img className='img' src={''}/> */}
                  <div className="forth">
                    <h5>{y.h5}</h5>
                  </div>
                </div>
                <label className="switch">
                  <input
                    type="checkbox"
                    name={y.name}
                    checked={value[y.name]}
                    onChange={handleChange}
                  />
                  <span className="slider round">
                    {value[y.name] ? (
                      <span className="yes">YES</span>
                    ) : (
                      <span className="no">NO</span>
                    )}
                  </span>
                </label>
              </div>
            ))}
            <div className="button btnWidth btnColor" onClick={''}>
              Create Team
            </div>
            <div style={{ fontSize: '15px' }} className="button btnWidth" onClick={''}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTeam;
