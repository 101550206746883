import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { routesPath } from '../../constants/routesPath';
import { Header } from '../../components/header/header';
import { Dashboard } from '../../components/dashboard/dashboard';
import clients from '../../redux/modules/clients';
import { Spinner } from '../../components/loader/loader';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { apiUrls } from '../../constants/apiurls';
import projects from '../../redux/modules/projects';
import { fetchAutoCompleteData } from '../../services/autoCompleteService';
import { OverlayContainer } from '../overlayContainer/OverlayContainer';
import { handleCurrentView, handleViewsData } from '../../redux/common/commonSlice';
import { views } from '../../constants/views';
import { useDispatch, useSelector } from 'react-redux';

export const DashboardContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [colors, setColours] = useState([]);

  const currentUIState = useSelector((state) => state.commonReducer.viewsData);

  const [clientSearchQuery, setClientSearchQuery] = useState(null);
  const [projectSearchQuery, setProjectSearchQuery] = useState(null);

  useEffect(() => {
    getInitialData();
  }, []);

  const [deleteClient, deleteClientData] = clients.delete().useClientsdeleteMutation();
  const { data: clientData, isFetching: isLoadingClient } = clients
    .list()
    .useClientslistQuery(
      { params: '', extendedPath: apiUrls.getClientListing() },
      { refetchOnMountOrArgChange: true },
    );

  const { data: searchedClients } = clients.list().useClientslistQuery(
    { params: '', extendedPath: apiUrls.getClientListing() },
    {
      selectFromResult: ({ data }) => ({
        data: data?.data?.filter((el) => el.title.toUpperCase().includes(clientSearchQuery)),
      }),
    },
  );

  const {
    data: projectsData,
    isLoading: projectsLoading,
    error: projectsError,
    isSuccess: projectsSuccess,
  } = projects.list().useProjectlistQuery({ params: '', extendedPath: apiUrls.getProjects() });

  const {
    data: searchedProjectsData,
    isLoading: searchedProjectsLoading,
    error: searchedProjectsError,
    isSuccess: searchedProjectsSuccess,
  } = projects.list().useProjectlistQuery(
    { params: '', extendedPath: apiUrls.getProjects() },
    {
      selectFromResult: ({ data }) => ({
        data: data?.data?.filter((el) => el.title.toUpperCase().includes(projectSearchQuery)),
      }),
    },
  );

  const filterList = (clients, query) => {
    setClientSearchQuery(query.toUpperCase());
  };

  const filterProjectsList = (projects, query) => {
    setProjectSearchQuery(query.toUpperCase());
  };

  const clientRemove = (client) => {
    dispatch(handleViewsData({ client: client, currentView: views.confirmationDialog }));
  };
  useEffect(() => {
    if (deleteClientData?.isSuccess) {
      toast.success('Client Sucessfully Deleted');
      dispatch(handleViewsData(null));
    }
    if (deleteClientData?.error) {
      toast.error('Client Deletion Failed');
      dispatch(handleViewsData(null));
    }
  }, [deleteClientData]);

  const getInitialData = async () => {
    let colrs = await fetchAutoCompleteData('', apiUrls.getColors());
    setColours(colrs);
  };

  return (
    <div className="overflow-hidden">
      <Header title="Dashboard" link={routesPath.dashboard} avatar={false} />

      {isLoadingClient ? (
        <div className="text-center ">
          <Spinner height="100vh" width="100" />
        </div>
      ) : (
        <Dashboard
          colors={colors}
          clients={
            isEmpty(searchedClients) && !clientSearchQuery ? clientData?.data : searchedClients
          }
          projects={projectsData?.data}
          searchedProjectsData={!projectSearchQuery ? projectsData?.data : searchedProjectsData}
          onClientSearch={(query) => filterList('clients', query)}
          onClientAdd={() => navigate(routesPath.clientFormPage, { replace: false })}
          onClientRemove={(client) => clientRemove(client)}
          onClientEdit={(client) => navigate(routesPath.editClient(client.id), { replace: false })}
          onlogoClick={(client) =>
            navigate(routesPath.getClientProjects(client.id), { replace: false })
          }
          onProjectSearch={(query) => filterProjectsList('projects', query)}
          onProjectAdd={(client) => navigate(routesPath.addProjects(client.id), { replace: false })}
        />
      )}
      {currentUIState?.currentView === views.confirmationDialog && (
        <OverlayContainer
          closeOverlay={() => {
            dispatch(handleViewsData(null));
          }}
          currentView={views.confirmationDialog}
          acceptConfirmation={() => {
            deleteClient({ body: { id: currentUIState.client.id } });
          }}
          declineConfirmation={() => dispatch(handleViewsData(null))}
        />
      )}
    </div>
  );
};
