import React from 'react';
import { isEmpty, forEach } from 'lodash';

import { Button } from '../button/button';
import { NavBar } from '../nav/navBar';
import { NavSegment } from '../nav/navSegment';
import { NavItem } from '../nav/navItem';
import { RoundButton } from '../roundButton/roundButton';
import { CardTitle } from '../cardTitle/cardTitle';
import { FaChevronLeft } from 'react-icons/fa';
import { BiFilter as FilterIcon } from 'react-icons/bi';

import styles from './header.module.scss';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { NavbarMobile } from '@components/nav/navMobile';

export const Header = ({
  backLink,
  backLinkMobile,
  backLinkButton,
  avatar,
  title,
  titleLeft,
  link,
  newEntry,
  content,
  children,
  onClick,
  onCancel,
  onCancelButton,
  isSearch,
  customButtons,
  doubleAvatar,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={`header ${isSearch && styles.search}`}>
        <NavBar type="submenu">
          <NavSegment className={styles.leftNav} placement="left">
            {isSearch && (
              <RoundButton onClick={onClick} className={styles.filtersButton}>
                <FilterIcon size={25} />
              </RoundButton>
            )}
            {backLink && (
              <NavItem
                link={backLink}
                className={classNames(styles.backLink, 'd-none', 'd-sm-block')}
              >
                <FaChevronLeft size={20} /> Back
              </NavItem>
            )}
            {backLinkMobile && (
              <>
                <div className="d-flex align-items-center">
                  <button
                    onClick={backLinkMobile}
                    className={classNames(styles.backLink, styles.backlinkMobile)}
                  >
                    <FaChevronLeft size={20} />
                  </button>
                  {children}
                </div>
              </>
            )}
            {backLinkButton && (
              <button
                onClick={() => navigate(-1)}
                className={classNames(
                  styles.backLink,
                  styles.backlinkButton,
                  'd-none',
                  'd-sm-block',
                )}
              >
                <FaChevronLeft size={20} /> Back
              </button>
            )}
            {titleLeft && (
              <NavItem>
                <CardTitle
                  title={titleLeft}
                  className={styles.cardNav}
                  size="small"
                  avatar={false}
                />
              </NavItem>
            )}
            {title && (
              <NavItem>
                <CardTitle
                  avatar={avatar && { name: title, ...avatar }}
                  title={title}
                  link={link}
                  onClick={onClick}
                  className={styles.cardNav}
                  size="small"
                />
              </NavItem>
            )}
            {doubleAvatar && <NavItem>{doubleAvatar}</NavItem>}

            {content && content}
          </NavSegment>

          <NavSegment placement={newEntry === true ? 'middle' : 'right'}>
            {!backLinkMobile && children}
            {/* {!isEmpty(customButtons) && !newEntry
          ? forEach(customButtons, (button, key) => <div key={key}>{button}</div>)
          : ''} */}
          </NavSegment>
          {onCancel ? (
            <NavSegment placement={'right'}>
              <NavItem
                link={onCancel}
                className={classNames(styles.cancelLink, 'd-none', 'd-sm-block')}
              >
                Cancel
              </NavItem>
            </NavSegment>
          ) : (
            ''
          )}

          {newEntry && onCancelButton ? (
            <NavSegment placement={'right'} style={{ padding: '0' }}>
              <NavItem>
                <Button onClick={onCancelButton} nav regular size={'small'}>
                  Cancel
                </Button>
              </NavItem>
            </NavSegment>
          ) : (
            ''
          )}
          {!isEmpty(customButtons) && newEntry ? (
            <NavSegment placement={'right'}>
              {forEach(customButtons, (button, key) => (
                <NavItem key={key}>{button}</NavItem>
              ))}
            </NavSegment>
          ) : (
            ''
          )}
        </NavBar>
      </div>
    </>
  );
};

Header.defaultProps = {
  isSearch: false,
};
