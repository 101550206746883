import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from '../button/button';
import { Dropdown } from '../dropDown/dropDown';
import { FormColumns } from '../formColumns/formColumns';
import styles from 'components/savedSearches/savedSearches.module.scss';
import { isEmpty, map } from 'lodash';
import search from '../../redux/modules/search';
import { Avatar, avatarOptions } from '../avatar/avatar';
import { Tag } from '../tag/tag';
import { TagsList } from '../tagsList/tagsList';
import classNames from 'classnames';
import { apiUrls } from '../../constants/apiurls';
import { handleCandidateSearchFilters } from '../../redux/common/commonSlice';
import { useDispatch } from 'react-redux';
import storageService from '../../services/storageService';

export const SavedSearches = (props) => {
  const {
    mySearches,
    otherUsersSearches,
    myProfile,
    recruiters,
    seniorityLevels,
    context,
    onSave,
    onDelete,
    onSaveSearches,
    recruiter,
    onLoad,
  } = props;
  const dispatch = useDispatch();

  const [selectedSearh, setSeletedSearch] = useState(null);

  const {
    data: savedSearchesList,
    isLoading: savedSearchesLoading,
    error: savedSearchesError,
    isSuccess: savedSearchesSuccess,
  } = search.list().useSearchlistQuery();

  const renderList = () =>
    !recruiter && (
      <div
        className={classNames(styles.recruiter, {
          [styles.show]: true,
        })}
        key={recruiter?.id}
      >
        {savedSearchesList?.data && (
          <div className="">
            <Avatar
              size={30}
              {...avatarOptions(JSON.parse(storageService.getUserInfo()), 'user')}
            />
          </div>
        )}

        {!isEmpty(savedSearchesList?.data) ? (
          <TagsList expanded={true}>
            {map(savedSearchesList?.data, (search) => (
              <>
                <Tag
                  key={search.id}
                  isDeletable={true}
                  onDelete={() => onDelete(search)}
                  onClick={() => onLoad(search)}
                  className={'mb-0'}
                >
                  {search.title}
                </Tag>
              </>
            ))}
          </TagsList>
        ) : (
          <div className={styles.noSearches}>
            <p>{"You don't have any saved searches yet"}</p>
          </div>
        )}
        {/* <If condition={length > 30}>
            <Link
              onClick={() =>
                this.setState({
                  expandedLists: {
                    ...this.state.expandedLists,
                    [recruiter.id]: !this.state.expandedLists[recruiter.id],
                  },
                })
              }
              className={styles.expand}
            >
              <CaretIcon size={20} />
            </Link>
          </If> */}
      </div>
    );

  return (
    <div className={styles.searchesContainer}>
      <div className={styles.searchesWrapper}>
        <FormColumns>
          <div className={classNames()}>
            <div className="row">
              <div className="col-lg-2 col-3">
                <div className={styles.searchesTitle}>
                  <h5 className="">Saved searches</h5>
                </div>
              </div>
              <div className="col-lg-8 col-9">
                <div className={styles.searchesContent}>
                  {renderList()}

                  {!isEmpty(otherUsersSearches) && (
                    <div
                      onClick={() => this.setState({ showAll: !this.state.showAll })}
                      className={styles.showAll}
                    >
                      Show {this.state.showAll ? 'less' : 'more'} recruiters
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-2 col-12 mt-4 mt-lg-0 d-flex">
                <Button secondary inline onClick={onSaveSearches} size="small">
                  Save this search
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.searchesAction}>
            {/* <Dropdown
              heading="Save this search"
              trigger={ */}

            {/* <SavedSearchesForm
                initialValues={context}
                seniorityLevels={seniorityLevels}
                onSubmit={onSave}
              /> */}
            {/* // </Dropdown> */}
          </div>
        </FormColumns>
      </div>
    </div>
  );
};
