import EditTeam from 'components/editTeam/EditTeam';
import React from 'react';

export const EditTeamContainer = () => {
  return (
    <div>
      <EditTeam />
    </div>
  );
};
