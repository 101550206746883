import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState: {
    navBarToggle: false,
    viewsData: {},
    wizardCurrentView: {
      step: 0,
    },
    candidateSearchFilters: '',
    candidatesFiltersUrl: '',
  },
  reducers: {
    handleNavToggle: (state, action) => {
      action.payload == undefined
        ? (state.navBarToggle = !state.navBarToggle)
        : (state.navBarToggle = action.payload);
    },
    handleViewsData: (state, action) => {
      state.viewsData = action?.payload;
    },
    handleCurrentView: (state, action) => {
      state.viewsData.currentView = action?.payload;
    },
    updateWizardStep: (state, action) => {
      state.wizardCurrentView = action?.payload;
    },
    handleCandidateSearchFilters: (state, action) => {
      state.candidateSearchFilters = action?.payload;
    },
    handleCandidateFiltersUrls: (state, action) => {
      state.candidatesFiltersUrl = action?.payload;
    },
  },
});

export const {
  handleNavToggle,
  handleViewsData,
  handleCurrentView,
  updateWizardStep,
  handleCandidateSearchFilters,
  handleCandidateFiltersUrls,
} = commonSlice.actions;
export default commonSlice.reducer;
