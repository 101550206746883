import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export const Spinner = (props) => {
  const { height, width } = props;

  return (
    <ThreeDots
      wrapperClass="justify-content-center"
      height={height}
      width={width}
      color="#384677"
    />
  );
};
