import { isEmpty } from 'lodash';
// import { ExternalLinkIcon, RefreshIcon } from 'components/Icons';
// import Tooltip from "components/Tooltip";

import classNames from 'classnames';
import styles from '../jobForm/jobForm.module.scss';
import statusStyles from './jobPostStatus.module.scss';
import { Button } from '../button/button';

// import apiService from '../services/apiService';
// import apiUrls from '../apiUrls';
import { JobPublishedOn } from '../jobPublishedOn/jobPublishedOn';
import { DateSelector } from '../form/dateSelector';
import { JobApplyRateStates } from '../jobApplyRateStats/jobApplyRateStates';

export const JobPostStatus = (props) => {
  const {
    showCreate,
    showConfirm,
    showPostConfirm,
    // handleSubmit,
    loading,
    onSubmit,
    job,
    isSubmitting,
    isEditing,
    refreshJobData,
  } = props;
  // const removeJob = (jobId) => {
  //   apiService.post(apiUrls.jobDelete, { id: jobId }).then((res) => {
  //     replace(routes.jobs);
  //   });

  return (
    <>
      {/* CREATE AND EDIT */}
      {showCreate && (
        <div className={classNames(styles.compCardContainer, styles.fixedCard)}>
          <div className={classNames(styles.compCardTitleContainer)}>
            <span className={classNames(styles.dividerTextLarge)}>Job post status</span>
          </div>
          <div className={classNames(styles.compCardContent, 'd-flex w-100 flex-column')}>
            <div className="row">
              <div className="col-6">
                <DateSelector
                  disabled={isEditing}
                  name="publish_date"
                  label="Published on:"
                  placeholder="Unpublished"
                  defaultValue="20-Feb-2022"
                />
              </div>
              <div className="col-6">
                <DateSelector
                  disabled={false}
                  name="expiry_date"
                  label="Expires on:"
                  placeholder="Expired On"
                  defaultValue="29-Jun-2022"
                />
              </div>
            </div>
            <JobApplyRateStates views={job && job.num_views} applied={null} />

            {/* TODO GET APPLIED COUNT */}
            {/* <JobPublishedOn headText={'Published on:'}/> */}
            {/* <JobPublishedOn headText={'Expired On:'} />
              <DateSelector name="end_date" /> */}

            {/* <JobApplyRateStats
              views={job && job.num_views}
              applied={null}
            />
            <JobPublishedOn
              text={
                null ||
                (job &&
                  job.published &&
                  moment(job.published).format("YYYY-MM-DD"))
              }
            />
            <JobExpiresOn input /> */}
            <div>
              <Button
                submit
                disabled={loading}
                type="submit"
                // onClick={onSubmit}
                // onClick={handleSubmit((values) => onSubmit({ ...values }))}
              >
                {isEditing ? 'Update job post' : 'Publish job post'}
              </Button>
            </div>
            {isEditing ? (
              <div>
                {/* <DateSelector name="end_date" label="Expires on:" placeholder="Unpublished" />
                  <Button
                    style={{ backgroundColor: '#FD3732', marginTop: '20px' }}
                    // onClick={() => removeJob(jobId)}
                  >
                    {'Delete job post'}
                  </Button> */}
              </div>
            ) : (
              ''
            )}
            {/* <div className={ classNames(styles.textSmall) }>
              { 'This only saves your post details. In the next step you can preview and then publish your job.' }
            </div> */}
          </div>
        </div>
      )}

      {/* CONFIRM */}
      {showConfirm && (
        <div className={classNames(styles.compCardContainer, styles.fixedCard)}>
          <div className={classNames(styles.compCardTitleContainer)}>
            <span className={classNames(styles.dividerTextLarge)}>Job post status</span>
          </div>
          <div className={classNames(styles.compCardContent)}>
            <div className={classNames(styles.col_1_1)}>
              {/* <JobPublishedOn
                text={
                  null ||
                  (job &&
                    job.created &&
                    moment(job.created).format("YYYY-MM-DD"))
                }
              />
              <JobExpiresOn input={false} text={job.expiration_date} /> */}
              <div className={classNames(styles.submitButtonWrapper)}>
                <Button
                  submit
                  disabled={isSubmitting}
                  // onClick={onSubmit}
                  type="submit"
                  // type="submit"
                >
                  Publish job post
                </Button>
              </div>
              <div className={classNames(styles.textSmall, statusStyles.confirmTextContentWrapper)}>
                <center>
                  {'This will publish your job post live, on the Hospitality Leaders website.'}
                </center>
              </div>
              {/* {!isEmpty(job.hl5_url) && (
                <center>
                  <AdvancedLink href={job.hl5_url} target="_blank">
                    <span className={classNames(statusStyles.linkText)}>
                      Preview post&nbsp;&nbsp;
                      <ExternalLinkIcon />
                    </span>
                  </AdvancedLink>
                </center>
              )}
              {isEmpty(job.hl5_url) && (
                <ReactTooltip
                  placement="top"
                  overlay={
                    <Tooltip
                      title="Job preview"
                      content="Click to attempt to retrieve the preview link.
                      It can take up to a minute for the link to be available."
                    />
                  }
                >
                  <center>
                    <AdvancedLink onClick={refreshJobData} target="_blank">
                      <span className={classNames(statusStyles.linkText)}>
                        Get Preview link&nbsp;&nbsp;
                        <RefreshIcon />
                      </span>
                    </AdvancedLink>
                  </center>
                </ReactTooltip>
              )} */}
            </div>
          </div>
        </div>
      )}
      {/* POST CONFIRM */}
      {showPostConfirm && (
        <div
          className={classNames(styles.compCardContainer, styles.fixedCard)}
          ref={(node) => (this.node = node)}
          style={{ height: this.props.calculatedHeight }}
        >
          <div className={classNames(styles.compCardTitleContainer)}>
            <span className={classNames(styles.dividerTextLarge)}>Job post status</span>
          </div>
          <div className={classNames(styles.compCardContent)}>
            <div className={classNames(styles.col_1_1)}>
              {/* <JobPublishedOn
                text={null || (job && job.published && moment(job.published).format('YYYY-MM-DD'))}
              /> */}
              {/* <JobExpiresOn input={false} text={job.expiration_date} /> */}
              {/* <div className={classNames(styles.submitButtonWrapper)}>
                <Button onClick={() => push(routes.editJob(job.id))} regular submit size={'small'}>
                  Edit job post
                </Button>
              </div> */}
              {job && job.hl5_url && (
                <div className={classNames(styles.textSmall)}>
                  <span>
                    {'Your post is live.'}
                    <a
                      className={classNames(statusStyles.linkText)}
                      rel="noopener noreferrer"
                      href={job.hl5_url}
                      target="_blank"
                    >
                      <span>View live job post</span>
                    </a>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
