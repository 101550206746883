import React from 'react';
import classNames from 'classnames';
import { filter, map, size } from 'lodash';
// import moment from 'moment'

import { MdAttachment as AttachmentIcon } from 'react-icons/md';
import { MdMoreVert as MoreIcon } from 'react-icons/md';

import { MenuItem } from 'components/menuItem/menuItem';
import { Dropdown } from 'components/dropDown/dropDown';
import { CardTitle } from 'components/cardTitle/cardTitle';
import { avatarOptions } from 'components/avatar/avatar';

// import { basename } from 'redux/modules/api';
// import { fullName } from 'redux/modules/talent';
// import { avatarSize } from 'redux/modules/ui';
// import apiService from '../services/apiService';
// import apiUrls from '../apiUrls';

import styles from './notesList.module.scss';
import dayjs from 'dayjs';
import { Button } from '../button/button';

export const NotesList = (props) => {
  const { className, notes, userId, onDeleteNote } = props;
  return (
    <div className={classNames(styles.noteItemWrapper, className)}>
      {map(notes, (note, index) => {
        return (
          <div key={`note-${index}`} className={styles.noteItem}>
            <div className={styles.noteMessage}>
              {note.note === 'emptyNote' ? 'N/A' : note.note}
            </div>
            {note.attachment !== null && size(note.attachment) && (
              <div className={styles.noteAttachments}>
                <a
                  style={{ color: '#32A3FD' }}
                  key={`note-${index}-${note.attachment}`}
                  href={JSON.parse(note.attachment).attachment}
                  className={styles.noteAttach}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AttachmentIcon /> {JSON.parse(note.attachment).name}
                </a>
              </div>
            )}
            <div className="d-flex" style={{ justifyContent: 'space-between' }}>
              {note.author && (
                <CardTitle
                  avatar={{ ...avatarOptions(note.author, 'user'), size: 20 }}
                  title={note?.author?.full_name}
                  subhead={note?.created}
                />
              )}
              <div>
                <div
                  style={{
                    background: 'transparent',
                    color: '#2FA4FD',
                    cursor: 'pointer',
                    fontSize: '14px',
                  }}
                >
                  Edit
                </div>
              </div>
            </div>
            <Dropdown
              zIndex={92}
              className={styles.columnHeaderMenu}
              trigger={<MoreIcon size={22} />}
            >
              <MenuItem onClick={() => onDeleteNote(note.id)}>Delete note</MenuItem>
            </Dropdown>
          </div>
        );
      })}
    </div>
  );
};
