import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoundButton } from '../../components/roundButton/roundButton';
import { SearchFiltersSwitch } from '../../components/searchFiltersSwitch/searchFiltersSwitch';
import { SearchForm } from '../../components/searchForm/searchForm';
import styles from '../../components/searchForm/searchForm.module.scss';
import { views } from '../../constants/views';
import { handleViewsData } from '../../redux/common/commonSlice';

export const CandidatesSearchContainer = () => {
  const dispatch = useDispatch();
  const currentUIState = useSelector((state) => state.commonReducer.viewsData);

  useEffect(() => {
    return () => {
      dispatch(handleViewsData(null));
    };
  }, []);

  return (
    <>
      <div className="mainPanelRight">
        <SearchFiltersSwitch
          onToggle={() => {
            currentUIState?.currentView !== views.filtersForm
              ? dispatch(handleViewsData(views.filtersForm))
              : dispatch(handleViewsData(null));
          }}
          onClick={() =>
            dispatch(
              handleViewsData(
                currentUIState?.currentView === views.filtersForm
                  ? null
                  : { currentView: views.filtersForm },
              ),
            )
          }
          isOpen={currentUIState?.currentView === views.filtersForm}
        />

        <SearchForm />
      </div>
    </>
  );
};
