import { Heading } from 'components/heading/heading';
import classNames from 'classnames';
import styles from './kanbanColumn.module.scss';
import { find } from 'lodash';
import { MdMoreVert as VerticalIcon } from 'react-icons/md';
import { Button } from '@components/button/button';

export const KanbanColumn = ({ title, id, candidateCount, actionBar, selectedCards }) => {
  return (
    <>
      <div className={classNames('d-flex justify-content-between', styles.kanbanColumn)}>
        <div className="titleBlock">
          <Heading size={'large'}>{title}</Heading>
          <p className={styles.countCandidates}> {candidateCount} Candidates</p>
        </div>
        <button className={styles.vertIcon}>
          <VerticalIcon />
        </button>
      </div>
      {find(selectedCards, { pipeline_stage_id: id }) && actionBar}
    </>
  );
};
