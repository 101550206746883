import React, { useCallback, useEffect, useState } from 'react';
import { MdAdd as AddIcon } from 'react-icons/md';
import { Button } from '../button/button';
import { Fieldset } from '../fieldSet/fieldSet';
import { Form } from '../form/form';
import { StageCard } from 'components/stageCard/stageCard';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';

import { MdClose as CloseIcon } from 'react-icons/md';
import { yupResolver } from '@hookform/resolvers/yup';
import dataService from '../../services/dataService';

import { TextInput } from '../form/textInput';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import stages from '../../redux/modules/stages';

export const PipelineForm = (props) => {
  const {
    children,
    yupSchema,
    createPipeline,
    pipelineData,
    isEdit,
    updatePipeline,
    refetchPipeline,
    formOpenHandler,
  } = props;

  const [createStage, stageCreateData] = stages.form().useStagecreateMutation();
  const [updateStage, stageUpdateData] = stages.form().useStageupdateMutation();

  const [initialValues] = useState(() => {
    return dataService.parsePipeline(pipelineData);
  });

  const form = useForm({
    resolver: yupResolver(yupSchema),
    mode: 'all',
    defaultValues: initialValues,
    // shouldUnregister: true,
  });

  const { fields, append, remove, move } = useFieldArray({
    control: form.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'pipeline_stages', // unique name for your Field Array
    keyName: 'field_id',
  });

  const handleSubmit = (values) => {
    values.pipeline_stages = values.pipeline_stages.map((item, index) => ({
      ...item,
      sort_order: index,
    }));
    isEdit ? updatePipeline({ ...values, id: pipelineData?.id }) : createPipeline({ body: values });
  };

  const createStageHandler = (index, title, id) => {
    if (isEdit) {
      if (id) {
        updateStage({ id: id, title: title, sort_order: index });
      } else {
        createStage({
          body: { title: title, sort_order: index },
          params: { pipelineId: pipelineData?.id },
        });
      }
    }
  };

  useEffect(() => {
    if (stageCreateData?.isSuccess || stageUpdateData?.isSuccess) {
      refetchPipeline();
    }
    if (stageCreateData?.error || stageUpdateData?.error) {
      toast.error('Faild to Update Pipeline Stages!');
    }
  }, [stageCreateData, stageUpdateData]);

  const deleteStageHandler = (index) => {
    remove(index);
  };

  const moveCard = useCallback(
    async (result) => {
      if (!result.destination) return;
      move(result.source.index, result.destination.index);
    },
    [fields],
  );

  return (
    <>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="form-fields ">
            <CloseIcon
              onClick={formOpenHandler}
              style={{ position: 'absolute', right: '40px', top: '10px', color: 'grey' }}
            />
            <div className="row border-bottom gx-0">
              <div className="col-12 col-md-4">
                <Fieldset
                  title="Hotel Details"
                  description="The client profile is displayed on all job advert posts when a job is posted to Hospitality Leaders."
                ></Fieldset>
              </div>
              <div className="col-md-8 col-12">
                <Fieldset>
                  <TextInput
                    type="text"
                    name="title"
                    placeholder="E.g. Senior position pipeline"
                    label=""
                  />
                </Fieldset>
              </div>
            </div>
            <div className="row border-bottom gx-0">
              <div className="col-12 col-md-4">
                <Fieldset
                  title="Define journey stages"
                  description="Define the different stages within this pipeline. This is the recruitment journey. E.g. Sourced, Phone screen, Interview, Offer, Hired"
                ></Fieldset>
              </div>
              <div className="col-12 col-md-8">
                <DragDropContext onDragEnd={moveCard}>
                  <Droppable droppableId="listStages">
                    {(provided) => (
                      <div
                        className="listStages"
                        id="listStages"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <Fieldset>
                          {fields?.length > 0
                            ? fields?.map((item, index) => {
                                return (
                                  <StageCard
                                    field_id={item.field_id}
                                    id={item.id}
                                    key={item.field_id}
                                    name={`pipeline_stages.${index}.title`}
                                    remove={() =>
                                      deleteStageHandler(
                                        index,
                                        // pipelineData?.pipeline_stages[index]?.id,
                                      )
                                    }
                                    index={index}
                                    createStage={createStageHandler}
                                  />
                                );
                              })
                            : null}

                          <Button
                            type="button"
                            id="addStageButton"
                            submit
                            onClick={() => append({ title: '' })}
                          >
                            <AddIcon /> Add another stage
                          </Button>
                        </Fieldset>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
          <Fieldset className={'pb-0'}>
            <Button type="submit" id="clientSubmitBtn" submit>
              {isEdit ? 'Update pipeline template' : 'Save pipeline template'}
            </Button>
          </Fieldset>
          <Fieldset className={'py-0'}>{children}</Fieldset>
        </Form>
      </FormProvider>
    </>
  );
};
